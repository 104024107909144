
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { object } from "yup";
import { mapGetters } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { ProductRxGroup } from "../../interfaces/ProductRxGroup";
import { ProductRx } from "../../interfaces/ProductRx";
import { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import store from "@/store";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";

export default mixins(CustomValidation).extend({
  mixins: [CustomValidation],
  name: "choose-quantity",
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    selectedProductSubscriptionAvailable: { default: false, type: Boolean },
    selectedFrequency: { default: null, type: Object },
  },
  data() {
    return {
      schema: object().shape({
        selectedProduct: object().typeError("Product is required.").required("Product is required."),
      }),
      selectedProductGroup: null as ProductRxGroup | null,
      selectedProduct: null as AffiliateProductRx | null,
      selectedProductTypeID: 1,
      isSubscription: false as boolean,
      isSubscriptionDiscountAvailable: false,
      fillSubscriptionFrequencyDays: null as number | null,
    };
  },
  created() {
    this.setupValidation(this.schema);
    this.importData(this.q.quest);
  },
  watch: {
    q(newVal) {
      this.importData(newVal.quest);
    },
    isSubscription(newVal, oldVal) {
      if (newVal && newVal != oldVal && this.selectedProduct && this.selectedProduct.isIntroductory && this.filteredProducts && this.filteredProducts.length > 0) {
        this.setProduct(this.filteredProducts[0]);
      }
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
    }),
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    productGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    filteredProducts(): Array<AffiliateProductRx> {
      var result = [] as Array<AffiliateProductRx>;
      if (!this.selectedProductGroup) return result;
      for (var i = 0; i < this.products.length; i++) {
        //&& (!this.isSubscription || (this.isSubscription && !this.products[i].isIntroductory))
        if (this.products[i].productRxGroupID === this.selectedProductGroup.id && this.products[i].id != 11) {
          //if (this.products[i].productRxGroupID === this.selectedProductGroup.id) {
          result.push(this.products[i]);
        }
      }
      return result.sort(function (a, b) {
        return a.fillQty - b.fillQty;
      });
    },
    nextText(): string {
      return "Continue";
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : "new";
    },
  },
  methods: {
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      this.selectedProductGroup = null;
      this.selectedProduct = null;
      this.isSubscriptionDiscountAvailable = false;
      for (var i = 0; i < this.productGroups.length; i++) {
        if (qProductRx && this.productGroups[i].id === qProductRx.productRxGroupID) {
          this.selectedProductGroup = this.productGroups[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (qProductRx && this.products[i].id === qProductRx.productRxID) {
          this.selectedProduct = this.products[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (this.selectedProductGroup && this.products[i].productRxGroupID === this.selectedProductGroup.id) {
          if (this.selectedProduct == null) {
            this.selectedProduct = this.products[i];
          }
          var p = this.products[i] as AffiliateProductRx;
          if (p && p.subscriptionDiscountPricePerPill && p.subscriptionDiscountPricePerPill > 0) {
            this.isSubscriptionDiscountAvailable = true;
          }
        }
      }
      this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isSubscription = qProductRx ? qProductRx.isSubscription : false;
      this.fillSubscriptionFrequencyDays = qProductRx ? qProductRx.fillSubscriptionFrequencyDays : this.FillSubscriptionFrequencyDays;
    },
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    getPrice(prod: AffiliateProductRx): number {
      var currentQTP = this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID);
      return prod.programPrice + (currentQTP && currentQTP.length && currentQTP[0].price ? currentQTP[0].price : 0);
    },
    pgStyle(p: AffiliateProductRx) {
      if (this.selectedProduct == p)
        if (p.isIntroductory) return "border: 1px solid #000000 !important; min-width:369px; max-width:475px";
        else return "border: 1px solid #000000 !important; min-width:369px; max-width:475px";
      else return "min-width:369px; max-width:475px";
    },
    setProduct(p: AffiliateProductRx) {
      this.selectedProduct = p;
    },
    getUpdatedData(selectedProductSubscriptionAvailable: boolean): QuestionnaireViewModel {
      if (!this.selectedProduct) return this.q.quest;
      var q = Object.assign({}, this.q.quest);
      var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
      qProductRx.productRxID = this.selectedProduct.id;
      qProductRx.productTypeID = this.selectedProductTypeID;
      qProductRx.isSubscription = this.isSubscription;
      qProductRx.fillSubscriptionFrequencyDays = this.fillSubscriptionFrequencyDays;
      q.qProductRxs = [qProductRx];
      if (this.selectedProduct.isIntroductory || !selectedProductSubscriptionAvailable) qProductRx.isSubscription = false;
      return q;
    },
    async getFreqsByProductID(id: number, cb: (arg0: boolean) => void) {
      var freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (freqs) {
        if (freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
          cb(true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
          cb(false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
        cb(false);
      }
    },
    next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          if (!this.selectedProduct || !this.selectedProduct.id) return;
          this.getFreqsByProductID(this.selectedProduct.id, (selectedProductSubscriptionAvailable) => {
            if (!this.selectedProduct) return;
            var skipShipping = false;
            if (this.selectedProduct.isIntroductory || !selectedProductSubscriptionAvailable) {
              skipShipping = true;
              this.isSubscription = false;
            }
            if (!this.isSubscription) {
              skipShipping = true;
              this.fillSubscriptionFrequencyDays = null;
              this.$emit("update:selectedFrequency", null);
            }
            this.$emit("saveandnavigate", "+", this.getUpdatedData(selectedProductSubscriptionAvailable), skipShipping);
          });
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getUpdatedData(this.isSubscription));
    },
  },
  components: { QuestWrapperComponent },
});
