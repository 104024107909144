
import { Answer } from "@/interfaces/Answer";
import Vue, { PropType, readonly } from "vue";
import swal from "sweetalert";

export default Vue.extend({
  props: {
    value: Array as PropType<Answer[]>,
    val: Object,
    label: String,
    singleSelect: Boolean,
    allowDeselect: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      checkedProxy: [] as Array<Answer>,
      origVal: [] as Array<Answer>,
      firedVal: {} as unknown,
    };
  },
  created() {
    this.origVal = this.val;
    this.checkedProxy = this.value;
    this.onChange(null, true);
  },
  watch: {
    value(newVal: Array<Answer>) {
      this.checkedProxy = newVal;
    },
  },
  computed: {
    checked: {
      get() {
        return this.value || [];
      },
      set(val: Array<Answer>) {
        if (!this.singleSelect) {
          this.checkedProxy = val;
          return;
        }
        if (this.allowDeselect || (val && (val as Array<Answer>).length > 0)) {
          if (val && val.length > 1) {
            this.checkedProxy = [(val as Array<Answer>)[(val as Array<Answer>).length - 1]];
          } else {
            this.checkedProxy = val;
          }
        }
      },
    },
    isSelected() {
      if (!this.val || !this.checkedProxy || this.checkedProxy.length == 0) return false;
      for (var i = 0; i < this.checkedProxy.length; i++) {
        if (this.checkedProxy[i].answerID) {
          if (this.checkedProxy[i].answerID === this.val.answerID) {
            return true;
          }
        } else {
          if (this.checkedProxy[i] === this.val) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    checkbox_onChange(evt: Event) {
      this.onChange(evt, false);
    },
    onChange(evt: Event | null, force: boolean) {
      console.log(`event: ${JSON.stringify(evt)}`);
      if (!this.readonly || force) {
        this.$emit("input", this.checkedProxy);
        if (this.isSelected) {
          this.$emit("selected", this.checkedProxy);
          console.log("this.val");
          console.log(this.val);
          console.log("this.firedVal");
          console.log(this.firedVal);
          if (this.val == this.firedVal) {
            console.log("onChange");
            swal("", this.val.AlertText, "warning");
            this.firedVal = {};
          }
        }
      } else {
        if (evt) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      }
    },
    onClick(evt: { preventDefault: () => void; stopPropagation: () => void } | null) {
      if (this.readonly) {
        if (evt) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        return false;
      }
      if (this.val.alertText && this.val.alertText.length > 0 && !this.isSelected) {
        //This runs prior to isSelected being set
        this.firedVal = this.val;
        if (window.navigator.userAgent.indexOf("MSIE ")) {
          console.log("onClick");
          console.log(this.isSelected);
          let div = document.createElement("div");
          div.innerHTML = this.val.alertText;
          swal({ content: { element: div } });
          this.firedVal = {};
        }
      }
    },
  },
});
