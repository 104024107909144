import axios from "axios";

import router from "./router";
import store from "./store";

const api = axios.create({});

api.interceptors.response.use(undefined, (err) => {
  return new Promise((resolve, reject) => {
    console.log(`axios.interceptors.response err ${err} err.response ${err.response.status}`);
    if ((err.response.status === 401 || err.response.status === 403) && err.response.config && !err.response.config.__isRetryRequest) {
      if (store.state.SignedIn) {
        store.dispatch("Refresh");
        if (!err.config._retried || err.config._retried < 3) {
          return new Promise((resolve) => setTimeout(resolve, 1000 * (err.config._retried ? err.config._retried + 1 : 1))).then(() => {
            err.config._retried = err.config._retried ? err.config._retried + 1 : 1;
            err.config.headers["Authorization"] = `Bearer ${store.state.Token}`;
            return resolve(api.request(err.config));
          });
        }
      }

      // if you ever get an unauthorized, logout the user
      store.dispatch("SignOut");
      // you can also redirect to /login if needed !
      if (router.currentRoute.name != "signin") router.push({ name: "signin", query: { r: router.currentRoute.fullPath } });
      return reject();
    }
    throw err;
  });
});

export default api;
