
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import router from "@/router";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Affiliate: "getAffiliate",
      getQuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
    }),
  },
  watch: {
    Content() {
      this.GetStarted();
    },
  },
  mounted() {
    this.GetStarted();
  },
  methods: {
    ...mapMutations({ setCurrentQuestType: "setCurrentQuestType", setShowCart: "setShowCart" }),
    GetStarted() {
      if (router.currentRoute.path != "/") return;
      if (this.Content) {
        if (this.Affiliate?.rootQuestionnaireTypeID) {
          this.LoadQuestionnaire(this.Affiliate.rootQuestionnaireTypeID);
          return;
        }
        const siteHomepage = this.Content.SiteHomepage;
        if (router.currentRoute.name == siteHomepage) return;
        this.$router.replace({ name: siteHomepage }).catch();
      }
    },
    LoadQuestionnaire(t: number) {
      this.setCurrentQuestType(t);
      router.push("/questionnaire");
    },
  },
});
