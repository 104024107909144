
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { PhotoVerifyViewModel } from "@/interfaces/PhotoVerifyViewModel";
import store from "@/store";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import swal from "sweetalert";

export default Vue.extend({
  data() {
    return {
      vm: null as PhotoVerifyViewModel | null,
    };
  },
  async mounted() {
    await this.RefreshQueue();
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Role: "getRole",
    }),
  },
  methods: {
    ...mapMutations({ SetLoading: "setLoading" }),
    cannedMessageClick(msg: string) {
      if (!this.vm) return;
      this.vm.photoVerifyData.photoReviewerComments = msg;
    },
    async RefreshQueue() {
      this.SetLoading(true);

      const roleEndpointMap = {
        PhotoVerify: "/api/photoverify/GetNextPatientToPhotoVerify",
        Support: "/api/support/GetNextPatientToPhotoVerify",
      };

      const endpoint = roleEndpointMap[this.Role];

      var result: AjaxReturnViewModel<PhotoVerifyViewModel> = await store.dispatch("GetNextPatientToPhotoVerify", endpoint);
      if (!result.success || !result.data) {
        this.vm = null;
        this.SetLoading(false);
        return;
      }
      this.vm = result.data;
      this.SetLoading(false);
    },
    async Approve() {
      this.SetLoading(true);
      const roleEndpointMap = {
        PhotoVerify: "/api/photoverify/ApprovePhoto",
        Support: "/api/support/ApprovePhoto",
      };
      const endpoint = roleEndpointMap[this.Role];

      var result: boolean = await store.dispatch("ApprovePhoto", { endpoint, patientID: this.vm?.photoVerifyData.patientID, patientAffiliateID: this.vm?.photoVerifyData.affiliateID, photoReviewerComments: this.vm?.photoVerifyData.photoReviewerComments });
      if (!result) {
        this.SetLoading(false);
        swal("Something went wrong!", "Please try again or contact your administrator.", "error");
        return;
      }
      await this.RefreshQueue();
    },
    async Deny() {
      this.SetLoading(true);

      const roleEndpointMap = {
        PhotoVerify: "/api/photoverify/DenyPhoto",
        Support: "/api/support/DenyPhoto",
      };
      const endpoint = roleEndpointMap[this.Role];

      var result: boolean = await store.dispatch("DenyPhoto", { endpoint, patientID: this.vm?.photoVerifyData.patientID, patientAffiliateID: this.vm?.photoVerifyData.affiliateID, photoReviewerComments: this.vm?.photoVerifyData.photoReviewerComments });
      if (!result) {
        this.SetLoading(false);
        swal("Something went wrong!", "Please try again or contact your administrator.", "error");
        return;
      }
      await this.RefreshQueue();
    },
    async Skip() {
      if (!this.vm?.photoVerifyData?.photoReviewSkipComments || this.vm?.photoVerifyData?.photoReviewSkipComments == "") {
        swal("Error!", "Please fill out skip comments.", "error");
        return;
      }
      this.SetLoading(true);
      const roleEndpointMap = {
        PhotoVerify: "/api/photoverify/SkipPhoto",
        Support: "/api/support/SkipPhoto",
      };
      const endpoint = roleEndpointMap[this.Role];

      var result: boolean = await store.dispatch("SkipPhoto", { endpoint, patientID: this.vm?.photoVerifyData.patientID, patientAffiliateID: this.vm?.photoVerifyData.affiliateID, photoReviewSkipComments: this.vm?.photoVerifyData.photoReviewSkipComments });
      if (!result) {
        this.SetLoading(false);
        swal("Something went wrong!", "Please try again or contact your administrator.", "error");
        return;
      }
      await this.RefreshQueue();
    },
  },
  components: {},
});
