
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import Vue, { PropType } from "vue";
import { mapGetters, mapMutations } from "vuex";
import confetti from "canvas-confetti";

export default Vue.extend({
  name: "visit-complete",
  props: {
    q: Object as PropType<QuestViewModel>,
    isSubChargePayment: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    isModify: { type: Boolean, default: false },
    paymentOnly: { type: Boolean, default: false },
  },
  mounted() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    stepType(): string {
      return this.isSubChargePayment ? "subchargepayment" : this.isPayment ? "payment" : this.isModify ? "modify" : "refill";
    },
    patientPortalText(): string {
      return this.Content.PatientPortalText && this.Content.PatientPortalText.length > 0 ? this.Content.PatientPortalText : "Dasboard";
    },
  },
  methods: {
    ...mapMutations({ SetLoading: "setLoading" }),
    next_click() {
      this.SetLoading(true);
      this.$emit("saveandnavigate", "+");
      this.SetLoading(false);
    },
  },
  components: { QuestWrapperComponent },
});
