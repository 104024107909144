
import { BCol, BContainer, BRow } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { RefillViewModel } from "@/interfaces/RefillViewModel";
import { ScreenEnum } from "@/interfaces/ScreenEnum";
import { StatusIDEnum } from "@/interfaces/StatusIDEnum";
import store from "@/store/index";
import router from "@/router";
import moment from "moment";
import { SubscriptionFrequency } from "@/interfaces/SubscriptionFrequency";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";

export default Vue.extend({
  data() {
    return {
      placeholder: false,
      screen: 0 as number,
      selectedProductSubscriptionAvailable: false as boolean,
      selectedFrequency: null as SubscriptionFrequency | null,
      adjustedDoctorConsult: 0 as number,
      adjustedProductPrice: 0 as number,
      medTotal: 0 as number,
      totalPrice: 0 as number,
      refillQuestType: 0 as number,
      numOfRefillsRemaining: -1 as number,
      num: 0 as number,
    };
  },
  props: {
    t: { type: String, default: null },
    numprop: { type: String, default: null },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Refills: "getRefills",
      SignedIn: "getSignedIn",
      CurrentQuestType: "getCurrentQuestType",
      CurrentRefillNum: "getCurrentRefillNum",
    }),
    q(): RefillViewModel {
      return this.Refills[this.refillQuestType];
    },
    underAge(): boolean {
      return moment().diff(moment(this.q.patient.dateOfBirth, "YYYY=MM-DD"), "years", true) < 18;
    },
  },
  async created() {
    if (this.numprop && this.t) {
      this.setCurrentQuestType(this.t);
      this.setCurrentRefillNum(this.numprop);
    }
    this.refillQuestType = this.CurrentQuestType;
    this.num = this.CurrentRefillNum;
    if (!this.refillQuestType) {
      router.push("/"); // Got here without picking a condition
      return;
    }
    if (!this.SignedIn) {
      if (router.currentRoute.name != "/signin") this.setScreen(ScreenEnum.SignUp);
      else this.setScreen(ScreenEnum.SignUp, true);
      return;
    }
    this.init();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setRefillQuest: "setRefillQuest",
      setCurrentQuestType: "setCurrentQuestType",
      setCurrentRefillNum: "setCurrentRefillNum",
    }),
    ensureScreen() {
      switch (router.currentRoute.name) {
        case "/refill/questions":
          this.setScreen(ScreenEnum.Questions, true);
          break;
        case "/refill/shippingpaymentinfo":
          this.setScreen(ScreenEnum.ShippingPaymentInfo, true);
          break;
        case "/refill/error":
          this.setScreen(ScreenEnum.Error, true);
          break;
        case "/refill/choosemedication":
          this.setScreen(ScreenEnum.ChooseMedication, true);
          break;
        case "/refill/choosequantity":
          this.setScreen(ScreenEnum.ChooseQuantity, true);
          break;
        case "/refill/chooseshipping":
          this.setScreen(ScreenEnum.ChooseShipping, true);
          break;
        case "/refill/productreview":
          this.setScreen(ScreenEnum.ProductReview, true);
          break;
        case "/refill/identityupload":
          this.setScreen(ScreenEnum.IdentityUpload, true);
          break;
        case "/refill/refillcomplete":
          this.setScreen(ScreenEnum.RefillComplete, true);
          break;
        case "/refill/refillchargeconfirmation":
          this.setScreen(ScreenEnum.RefillChargeConfirmation, true);
          break;
        default:
          break;
      }
    },
    async init() {
      this.setLoading(true);
      this.ensureScreen();
      this.numOfRefillsRemaining = this.num && this.num > 0 ? this.num : this.numOfRefillsRemaining;
      var arVM: AjaxReturnViewModel<RefillViewModel> = await store.dispatch("GetRefill", { t: this.refillQuestType, num: this.numOfRefillsRemaining });
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        this.setLoading(false);
        return;
      }
      var q: RefillViewModel = arVM.data;
      if (!q || !q.quest || q.refillHeld || q.quest.hardStop) {
        this.setScreen(ScreenEnum.Error);
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID == StatusIDEnum.AwaitingVisitScheduling) {
        this.setScreen(ScreenEnum.ScheduleVisit);
        this.setLoading(false);
        return;
      }
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID != StatusIDEnum.PaymentProcessed) {
        router.push("/patientportal");
        this.setLoading(false);
        return;
      }
      var productRxID = this.q.quest.qProductRxs && this.q.quest.qProductRxs.length > 0 ? this.q.quest.qProductRxs[0].productRxID : 0;
      var freqs = await store.dispatch("GetFreqsByProductRxID", productRxID);
      if (freqs)
        if (freqs.length > 0) this.selectedProductSubscriptionAvailable = true;
        else this.selectedProductSubscriptionAvailable = false;
      else this.selectedProductSubscriptionAvailable = false;

      if (router.currentRoute.name == "refill") this.pageLoadNavigate();
      this.setLoading(false);
    },
    async navigate(dir: string, lastQuestionScreen?: number | null, forceScreenChange?: boolean | null, skipShipping?: boolean | null) {
      console.log(`this.screen ${this.screen}`);
      console.log(`dir ${dir}`);
      console.log(`forceScreenChange ${forceScreenChange}`);
      console.log(`lastQuestionScreen ${lastQuestionScreen}`);
      console.log(`skipShipping ${skipShipping}`);
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      switch (this.screen) {
        case ScreenEnum.ShippingPaymentInfo:
          if (dir === "+")
            if (this.q.forceNewProduct) {
              this.setScreen(ScreenEnum.ChooseQuantity);
            } else {
              if (this.selectedProductSubscriptionAvailable) this.setScreen(ScreenEnum.ChooseShipping);
              else this.setScreen(ScreenEnum.ProductReview);
            }
          else {
            if (this.q.paymentOnly) {
              router.push("/patientportal");
              return;
            }
            this.setScreen(ScreenEnum.Questions);
          }
          break;
        case ScreenEnum.Questions:
          if (dir === "+")
            if (!forceScreenChange && lastQuestionScreen !== null) {
              this.setScreen(ScreenEnum.Questions, true);
            } else {
              this.setScreen(ScreenEnum.ShippingPaymentInfo);
            }
          else {
            if (!forceScreenChange && lastQuestionScreen !== null) {
              this.setScreen(ScreenEnum.Questions, true);
            } else {
              router.push("/patientportal");
            }
          }
          break;
        case ScreenEnum.RefillComplete:
          if (dir === "+") router.push("/patientportal");
          break;
        case ScreenEnum.ChooseQuantity:
          if (dir === "+") {
            this.setScreen(ScreenEnum.ChooseShipping);
          } else {
            this.setScreen(ScreenEnum.ShippingPaymentInfo);
          }
          break;
        case ScreenEnum.ChooseShipping:
          if (dir === "+") {
            this.setScreen(ScreenEnum.ProductReview);
          } else {
            if (this.q.forceNewProduct) {
              this.setScreen(ScreenEnum.ChooseQuantity);
            } else {
              this.setScreen(ScreenEnum.ShippingPaymentInfo);
            }
          }
          break;
        case ScreenEnum.ProductReview:
          if (dir === "+") {
            if (this.q.currentOTCCart != null && this.q.currentOTCCart.length > 0) this.setScreen(ScreenEnum.OTCCart);
            else this.setScreen(ScreenEnum.RefillComplete);
            //TODO: this.recordConversion();
          } else {
            if (this.selectedProductSubscriptionAvailable) this.setScreen(ScreenEnum.ChooseShipping);
            else this.setScreen(ScreenEnum.ShippingPaymentInfo);
          }
          break;
        default:
        // do nothing
      }
      this.setLoading(false);
    },
    pageLoadNavigate() {
      if (this.q.paymentOnly) {
        this.setScreen(ScreenEnum.ShippingPaymentInfo);
        return;
      }
      if (this.q.quest && this.q.quest.lastScreen) {
        if (this.q.quest.lastScreen === ScreenEnum.Questions) {
          this.setScreen(this.q.quest.lastScreen); //this.q.quest.questionsObj.lastQuestionScreen);
        } else {
          this.setScreen(this.q.quest.lastScreen);
        }
      } else {
        this.setScreen(ScreenEnum.Questions);
      }
    },
    async saveandnavigate(dir: string, data: any, data2: any) {
      this.ensureScreen();
      var skipShipping = false;
      let result = null;
      window.scrollTo(0, 0);
      this.setLoading(true);
      console.log(`saveandnavigate ${this.screen} ${dir} num ${this.num}`);
      switch (this.screen) {
        case ScreenEnum.Questions:
          var q = Object.assign({}, this.q);
          q.refillQuestionsObj = data;
          var forceScreenChange = data2;
          result = await store.dispatch("SaveRefillQuestions", { q: q.refillQuestionsObj, t: this.refillQuestType, l: forceScreenChange });
          if (!result) {
            if (this.q && this.q.hardStop) this.setScreen(ScreenEnum.NotYet);
            else this.setScreen(ScreenEnum.Error);
            this.setLoading(false);
            return;
          }
          this.navigate(dir, this.q.refillQuestionsObj.lastQuestionScreen, forceScreenChange);
          break;
        case ScreenEnum.ShippingPaymentInfo:
          await store.dispatch("SavePatient", { p: data, quest: data2, t: this.refillQuestType });
          this.setRefillQuest({ q: data2, t: this.refillQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.RefillComplete:
          this.navigate(dir);
          break;
        case ScreenEnum.ChooseQuantity:
          this.setRefillQuest({ q: data, t: this.refillQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ChooseShipping:
          this.setRefillQuest({ q: data, t: this.refillQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ProductReview:
          this.setRefillQuest({ q: data, t: this.refillQuestType });
          if (dir === "+" && (this.q.currentOTCCart == null || this.q.currentOTCCart.length == 0)) {
            result = await store.dispatch("SaveRefill", { rVM: this.q, num: this.numOfRefillsRemaining });
            if (!result) {
              if (this.q && this.q.hardStop) this.setScreen(ScreenEnum.NotYet);
              else this.setScreen(ScreenEnum.Error);
              this.setLoading(false);
              return;
            }
          }
          this.navigate(dir);
          break;
        default:
        // do nothing
      }
    },
    setScreen(s: number | ScreenEnum, noPush?: boolean) {
      console.log(`setScreen ${s}`);
      if (s !== ScreenEnum.Questions && s === this.screen) return;
      // If we've already paid but need to schedule, make sure we only can schedule
      if (this.q && this.q.quest && this.q.quest.statusID == 2 && this.screen != ScreenEnum.VisitComplete && this.screen != ScreenEnum.ScheduleVisit) {
        this.q.quest.lastScreen = ScreenEnum.ScheduleVisit;
        s = ScreenEnum.ScheduleVisit;
      }
      if (this.q && this.q.quest) {
        this.q.quest.lastScreen = s;
      }
      this.screen = s;

      if (noPush) {
        //history.pushState({}, "", document.location.href.split("#")[0] + "#" + s + ":" + qs);
      } else {
        switch (s) {
          case ScreenEnum.ChooseMedication:
            router.push("/refill/choosemedication");
            break;
          case ScreenEnum.ChooseQuantity:
            router.push("/refill/choosequantity");
            break;
          case ScreenEnum.ChooseShipping:
            router.push("/refill/chooseshipping");
            break;
          case ScreenEnum.ProductReview:
            router.push("/refill/productreview");
            break;
          case ScreenEnum.Questions:
            router.push("/refill/questions");
            break;
          case ScreenEnum.ShippingPaymentInfo:
            router.push("/refill/shippingpaymentinfo");
            break;
          case ScreenEnum.RefillChargeConfirmation:
            router.push("/refill/refillchargeconfirmation");
            break;
          case ScreenEnum.RefillComplete:
            router.push("/refill/refillcomplete");
            break;
          case ScreenEnum.Error:
            router.push("/refill/error");
            break;
          case ScreenEnum.NotYet:
            router.push("/refill/sorry");
            break;
          default:
            break;
        }
      }
      this.setLoading(false);
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
  },
});
