
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  props: {},
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      Theme: "getTheme",
      Content: "getContent",
    }),
  },
  mounted() {
    this.setLoading(false);
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
  },
});
