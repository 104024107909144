
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { object } from "yup";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { ProductRxGroup } from "../../interfaces/ProductRxGroup";
import { ProductRx } from "../../interfaces/ProductRx";
import { PromoViewModel } from "../../interfaces/PromoViewModel";
import { PromotionProduct } from "../../interfaces/PromotionProduct";
import { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import swal from "sweetalert";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import store from "@/store";
import PromoPricing from "@/mixins/PromoPricing";
import { mapGetters } from "vuex";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";

export interface VueOptions {
  filters: any;
}

export default mixins(CustomValidation, PromoPricing).extend({
  mixins: [CustomValidation, PromoPricing],
  name: "product-review",
  template: "#product-review-template",
  props: {
    selectedFrequency: { default: null, type: Object },
    paymentOnly: { type: Boolean, default: false },
    selectedProductSubscriptionAvailable: { default: false, type: Boolean },
    outsidePromo: { type: String, default: null },
  },
  data() {
    return {
      userSelectedProduct: false,
      consultInfo: "The online consult is charged separate from the medication. If it's found you do not qualify for medication, your visit will be refunded.",
      shipInfo: "Expedited 2 day shipping, for when you need it in a hurry.",
      subscriptionInfoToolTip: "Automatically receive your refills without having to log back in and request them.\n\n 30ct. ships/bills every 2 months.\n 60ct. ships/bills every 4 months.\n 90ct. ships/bills every 6 months.\n 180ct. ships/bills every 12 months.",
      subscriptionInfo: "Automatically receive your refills without having to log back in and request them.<br><br> 30ct. ships/bills every 2 months.<br> 60ct. ships/bills every 4 months.<br> 90ct. ships/bills every 6 months.<br> 180ct. ships/bills every 12 months.",
      subscriptionPromptBody: "Would you like to automatically receive your refills without having to log back in and request them?<br><br> 30ct. ships/bills every 2 months.<br> 60ct. ships/bills every 4 months.<br> 90ct. ships/bills every 6 months.<br> 180ct. ships/bills every 12 months.",
      schema: object().shape({
        selectedProduct: object().typeError("Product is required.").required("Product is required."),
      }),
    };
  },
  async created() {
    this.importData(this.q.quest);
  },
  watch: {
    q(newVal) {
      this.importData(newVal.quest);
    },
    everyonePromoProd(newVal: PromotionProduct) {
      if (this.userSelectedProduct && newVal && newVal.alertText && newVal.alertText.length > 0) swal("", newVal.alertText, "");
      this.userSelectedProduct = false;
    },
    outsidePromo(newVal: string) {
      if (newVal !== null && this.promoCode != newVal) {
        this.promoCode = newVal;
        this.GetPromoByCode(this.promoCode);
      }
    },
  },
  computed: {
    ...mapGetters({
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
    }),
    questionnaireTypeProduct(): QuestionnaireTypeProductViewModel {
      return this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID)[0];
    },
    everyonePromoDiscountAmount(): number {
      return this.mixinGetPromoDiscountAmount(this.everyonePromoProd);
    },
    expeditedShippingBtnTxt(): string | undefined {
      if (!this.$options) return;
      if (this.isExpeditedShipping) return "Remove";
      else return "Add +" + (this.$options as VueOptions).filters.toCurrencyNoChange(this.q.expeditedShippingFee - this.q.standardShippingFee);
    },
    nextText(): string {
      //if (this.isRefill && (this.currentotccart == null || this.currentotccart.length == 0)) return "ORDER REFILL";
      if (this.isRefill) return "Order Refill";
      else return "Continue";
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : "new";
    },
    subtotalPrice(): number {
      if (!this.isRefill && !this.isPayment) return this.q.onlineDoctorConsultFee + this.drugPrice + this.shippingPrice;
      else return this.drugPrice + this.shippingPrice;
    },
    isSubscriptionAvailable(): boolean {
      return this.isSubscription && this.selectedFrequency !== null;
    },
    isSubscriptionText(): string {
      if (this.isSubscriptionAvailable && this.selectedProducts) {
        return "Ships " + this.selectedFrequency.description;
      } else {
        return "";
      }
    },
  },
  methods: {
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
            break;
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as AffiliateProductRx[];
      }
      //this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isExpeditedShipping = newVal.isExpeditedShipping;
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.outsidePromo !== null) {
        newVal.promoCode = this.outsidePromo;
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.PassedPromoCode !== null) {
        newVal.promoCode = this.PassedPromoCode;
      }
      if (this.promoCode != newVal.promoCode) {
        this.promoCode = newVal.promoCode;
        if (this.promoCode && this.promoCode.length > 0)
          this.GetPromoByCode(this.promoCode, null, () => {
            this.promoCode = null;
          });
      }
      this.isSubscription = qProductRx ? qProductRx.isSubscription : this.isSubscription;
    },
    getUpdatedData() {
      var q = Object.assign({}, this.q.quest);
      q.isExpeditedShipping = this.isExpeditedShipping;
      q.promoCode = this.promoCode;
      return q;
    },
    async GetPromoByCode(code: string | null, cb?: null | (() => void), failCB?: null | (() => void)) {
      if (!code) return;
      this.codePromo = await store.dispatch("GetPromoByCode", { t: this.q.quest.questionnaireTypeID, code: code, notInitialQuest: false });
      if (this.codePromo) {
        this.promoCode = code;
        if (cb) cb();
      } else {
        if (failCB) failCB();
      }
    },
    onlineDoctorConsultMoreInfo_click() {
      swal("", this.consultInfo, "info");
    },
    toggleExpeditedShipping() {
      this.isExpeditedShipping = !this.isExpeditedShipping;
    },
    toggleIsSubscription(newVal: boolean) {
      this.isSubscription = newVal;
    },
    applyCouponBtn_click() {
      this.GetPromoByCode(this.promoCode, null, () => {
        swal("Oh no!", "Your coupon code wasn't found or may be expired. Please check that you spelled it correctly.", "warning");
      });
    },
    clearCouponBtn_click() {
      this.codePromo = null;
      this.promoCode = null;
    },
    next_click() {
      this.$emit("saveandnavigate", "+", this.getUpdatedData());
    },
    prev_click() {
      var skipShipping = false;
      if ((this.selectedProducts && this.selectedProducts[0].isIntroductory) || !this.selectedProductSubscriptionAvailable || !this.isSubscription) skipShipping = true;
      this.$emit("saveandnavigate", "-", this.getUpdatedData(), skipShipping);
    },
    shippingMoreInfo_click() {
      swal("", this.shipInfo, "info");
    },
    subscriptionMoreInfo_click() {
      swal("", this.subscriptionInfo, "info");
    },
    product_select() {
      this.userSelectedProduct = true;
    },
  },
  components: { QuestWrapperComponent },
});
