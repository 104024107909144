export enum StatusIDEnum {
  Incomplete = 0,
  Submitted = 1,
  AwaitingVisitScheduling = 2,
  Assigned = 3,
  InReview = 4,
  //Approved = 5,
  AwaitingPayment = 6,
  PaymentProcessed = 7,
  PaymentFailed = 8,
  SentToPharmacy = 9,
  Shipped = 10,
  ConsultCompleted = 11,
  PhotoRejected = 88,
  //Denied = 99,
  AwaitingPhotoVerification = 100,
  HardStop = 101,
  SubscriptionChargePaymentFailed = 102,
}
