
import { BCol, BContainer, BRow } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ScreenEnum } from "@/interfaces/ScreenEnum";
import { StatusIDEnum } from "@/interfaces/StatusIDEnum";
import store from "@/store/index";
import router from "@/router";
import { SubscriptionFrequency } from "@/interfaces/SubscriptionFrequency";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";

export default Vue.extend({
  props: {
    outsidePromo: { type: String, default: null },
  },
  data() {
    return {
      placeholder: false,
      screen: 0 as number,
      selectedProductSubscriptionAvailable: false as boolean,
      selectedFrequency: null as SubscriptionFrequency | null,
      adjustedDoctorConsult: 0 as number,
      adjustedProductPrice: 0 as number,
      medTotal: 0 as number,
      totalPrice: 0 as number,
      paymentQuestType: 0 as number,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Payments: "getPayments",
      SignedIn: "getSignedIn",
      CurrentQuestType: "getCurrentQuestType",
    }),
    q(): PaymentViewModel {
      return this.Payments[this.paymentQuestType];
    },
  },
  async created() {
    this.paymentQuestType = this.CurrentQuestType;
    if (!this.paymentQuestType) {
      router.push("/"); // Got here without picking a condition
      return;
    }
    if (!this.SignedIn) {
      if (router.currentRoute.name != "/signin") router.push("/signin");
      return;
    }
    this.init();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setPaymentQuest: "setPaymentQuest",
      setPaymentPatient: "setPaymentPatient",
    }),
    ensureScreen() {
      switch (router.currentRoute.name) {
        case "/subchargepayment/error":
          this.setScreen(ScreenEnum.Error, true);
          break;
        case "/subchargepayment/checkout":
          this.setScreen(ScreenEnum.Checkout, true);
          break;
        case "/subchargepayment/complete":
          this.setScreen(ScreenEnum.RefillComplete, true);
          break;
        default:
          break;
      }
    },
    async init() {
      this.setLoading(true);
      this.ensureScreen();
      var arVM: AjaxReturnViewModel<PaymentViewModel> = await store.dispatch("GetSubChargePayment", { t: this.paymentQuestType });
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        this.setLoading(false);
        return;
      }
      var q: PaymentViewModel = arVM.data;
      if (!q || !q.quest) {
        router.push("/subchargepayment/error");
        this.setLoading(false);
        return;
      }
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      /*if (q.quest.statusID != StatusIDEnum.SubscriptionChargePaymentFailed) {
        router.push("/patientportal");
        this.setLoading(false);
        return;
      }*/
      this.pageLoadNavigate();
      this.setLoading(false);
    },
    async navigate(dir: string, lastQuestionScreen?: number | null, forceScreenChange?: boolean | null, skipShipping?: boolean | null) {
      console.log(`this.screen ${this.screen}`);
      console.log(`dir ${dir}`);
      console.log(`forceScreenChange ${forceScreenChange}`);
      console.log(`lastQuestionScreen ${lastQuestionScreen}`);
      console.log(`skipShipping ${skipShipping}`);
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      switch (this.screen) {
        case ScreenEnum.Checkout:
          if (dir === "+") {
            this.setScreen(ScreenEnum.RefillComplete);
          } else {
            router.push("/patientportal");
          }
          break;
        case ScreenEnum.RefillComplete:
          if (dir === "+") router.push("/patientportal");
          break;
        default:
        // do nothing
      }
      this.setLoading(false);
    },
    pageLoadNavigate() {
      if (this.q.quest && this.q.quest.lastScreen) {
        this.setScreen(this.q.quest.lastScreen);
      } else {
        this.setScreen(ScreenEnum.Checkout);
      }
    },
    async saveandnavigate(dir: string, data: any, data2: any, data3: any) {
      this.ensureScreen();
      var skipShipping = false;
      window.scrollTo(0, 0);
      this.setLoading(true);
      console.log(`saveandnavigate ${this.screen} ${dir}`);
      switch (this.screen) {
        case ScreenEnum.Checkout:
          if (dir === "+") {
            await store.dispatch("SavePatient", { p: data, quest: data2, t: this.paymentQuestType, isPayment: true });
            var result = await store.dispatch("SaveSubChargePayment", { q: data2, t: this.paymentQuestType, a: this.q.subChargeAmount });
            if (result) this.navigate(dir);
            else this.setScreen(ScreenEnum.Error);
          } else this.navigate(dir);
          break;
        case ScreenEnum.RefillComplete:
          this.navigate(dir);
          break;
        default:
        // do nothing
      }
    },
    setScreen(s: number | ScreenEnum, noPush?: boolean) {
      console.log(`setScreen ${s}`);
      if (s !== ScreenEnum.Questions && s === this.screen) return;
      if (this.q && this.q.quest) {
        this.q.quest.lastScreen = s;
      }
      this.screen = s;

      if (noPush) {
        //history.pushState({}, "", document.location.href.split("#")[0] + "#" + s + ":" + qs);
      } else {
        switch (s) {
          case ScreenEnum.Checkout:
            router.push("/subchargepayment/checkout");
            break;
          case ScreenEnum.RefillComplete:
            router.push("/subchargepayment/complete");
            break;
          case ScreenEnum.Error:
            router.push("/subchargepayment/error");
            break;
          case ScreenEnum.NotYet:
            router.push("/subchargepayment/sorry");
            break;
          default:
            break;
        }
      }
      this.setLoading(false);
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
  },
});
