export enum QuestionTypeEnum {
  SingleChoice = 1,
  MultipleChoice = 2,
  FreeText = 3,
  MultipleFreeText = 4,
  Image = 5,
  IconMultipleChoice = 6,
  IconSingleChoice = 7,
  BMICalculator = 8,
  Information = 9,
}
