
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import { number, object, string, date } from "yup";
import swal from "sweetalert";
import moment from "moment";
import store from "@/store";
import { mapGetters, mapMutations } from "vuex";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import { VueTelInput } from "vue-tel-input";
import { SupportedState } from "@/interfaces/SupportedState";

export default mixins(CustomValidation, common).extend({
  name: "patient-profile",
  mixins: [CustomValidation, common],
  props: {
    payload: Object,
    email: String,
  },
  data() {
    return {
      viewModel: {
        Patient: {} as PatientViewModel,
        PatientStates: [] as string[],
        ShippableStates: [] as SupportedState[],
        PatientStatesMustMatch: false as boolean,
      },
      firstName: null as string | null,
      lastName: null as string | null,
      dobMonth: null as string | null,
      dobDay: null as string | null,
      dobYear: null as string | null,
      phone: "" as string,
      phoneIsValid: false as boolean,
      address1: null as string | null,
      address2: null as string | null,
      city: null as string | null,
      state: null as string | null,
      zip: null as string | null,
      homeAddress1: null as string | null,
      homeAddress2: null as string | null,
      homeCity: null as string | null,
      homeState: null as string | null,
      homeZip: null as string | null,
      shippingIsSame: true,
      schema: object().shape({
        address1: string().typeError("Address is required.").required("Address is required."),
        city: string().typeError("City is required.").required("City is required."),
        state: string().typeError("State is required.").required("State is required."),
        zip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
        homeAddress1: string().typeError("Address is required.").required("Address is required."),
        homeCity: string().typeError("City is required.").required("City is required."),
        homeState: string().typeError("State is required.").required("State is required."),
        homeZip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
        dobMonth: number().integer().typeError("Invalid Month.").min(0, "Invalid Month.").max(12, "Invalid Month.").required(),
        dobDay: number().integer().typeError("Invalid Day.").min(0, "Invalid Day.").max(31, "Invalid Day.").required(),
        dobYear: number().integer().typeError("Invalid Year.").min(1900, "Invalid Year.").max(3000, "Invalid Year.").required(),
        dateOfBirthValidation: date().typeError("Invalid Birthdate.").required(),
        phone: string()
          .typeError("Phone Number is required.")
          .matches(/^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/, "Invalid Phone Number.")
          .required("Phone Number is required."),
      }),
    };
  },
  computed: {
    ...mapGetters({
      Role: "getRole",
    }),

    dateOfBirthValidation(): Date | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return moment(this.dateOfBirth, "YYYY-MM-DD").toDate();
      }
      return null;
    },
    dateOfBirth(): string | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return this.dobYear + "-" + this.dobMonth + "-" + this.dobDay;
      }
      return null;
    },
  },
  mounted() {
    this.reset();
  },
  watch: {
    payload: {
      handler(newValue) {
        this.reset();
      },
      deep: true,
    },
    address1(newVal) {
      this.homeAddress1 = this.shippingIsSame ? newVal : this.homeAddress1;
    },
    address2(newVal) {
      this.homeAddress2 = this.shippingIsSame ? newVal : this.homeAddress2;
    },
    city(newVal) {
      this.homeCity = this.shippingIsSame ? newVal : this.homeCity;
    },
    state(newVal) {
      if (!newVal) return;
      console.log(`newVal ${newVal}`);
      console.log(`homeState ${this.homeState}`);
      console.log(`state ${this.state}`);
      if (this.shippingIsSame || this.viewModel.PatientStatesMustMatch) {
        this.checkSupportedState(this.state, (supported) => {
          if (!supported) {
            this.homeState = "";
            this.state = "";
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          } else {
            this.homeState = newVal;
          }
        });
      }
    },
    zip(newVal) {
      this.homeZip = this.shippingIsSame ? newVal : this.homeZip;
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    reset() {
      this.getPatientProfile();
    },
    dobDay_onKeyUp() {
      if (this.dobDay !== null && this.dobDay.length === 2) {
        (this.$refs.dobYear as HTMLElement).focus();
      }
    },
    dobMonth_onKeyUp() {
      if (this.dobMonth !== null && this.dobMonth.length === 2) {
        (this.$refs.dobDay as HTMLElement).focus();
      }
    },
    async getPatientProfile() {
      var postData = null;
      if (this.payload && this.payload.patientUserID) postData = { patientUserID: this.payload.patientUserID, patientAffiliateID: this.payload.patientAffiliateID };
      this.setLoading(true);
      const result = await store.dispatch("GetPatientProfile", postData);
      if (!result) {
        this.setLoading(false);
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      this.viewModel.Patient = result.data.patient;
      this.viewModel.PatientStates = result.data.patientStates;
      this.viewModel.ShippableStates = result.data.shippableStates;
      this.viewModel.PatientStatesMustMatch = result.data.patientStatesMustMatch;
      this.importData(this.viewModel.Patient);
      this.setLoading(false);
    },
    async savePatientProfile() {
      this.setLoading(true);
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(async () => {
          this.errors = [];

          this.setLoading(true);
          const result = await store.dispatch("SavePatientProfile", this.getUpdatedData());
          this.setLoading(false);

          if (!result) {
            this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
            return;
          }

          if (this.Role === "Support") {
            document.location.href = `/support/customerdetail/?lastName=${this.lastName ? encodeURIComponent(this.lastName) : ""}&email=${encodeURIComponent(this.email ? this.email : "")}&id=${this.viewModel.Patient.id}`;
          }

          this.$bvToast.toast("Profile Saved!", { title: "Success", autoHideDelay: 3000, appendToast: true });
          if (this.payload && this.payload.onSavedCB) this.payload.onSavedCB();
        })
        .catch((err: { inner: any[] }) => {
          this.setLoading(false);
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    cancelPatientProfile() {
      if (this.payload && this.payload.onCancelCB) this.payload.onCancelCB();
    },
    async checkSupportedState(state: string | null, cb: { (supported: boolean): void }) {
      var stateSupported = await store.dispatch("CheckSupportedState", { state: state, p: this.getUpdatedData(), isPatientProfile: true, isRefill: false, isPayment: false });
      if (cb) cb(stateSupported);
    },
    importData(newVal: PatientViewModel | null) {
      if (newVal == null) return;
      var mDob = null;
      if (newVal.dateOfBirth !== null && newVal.dateOfBirth.length > 0) {
        mDob = moment(newVal.dateOfBirth, "YYYY-MM-DD");
        this.dobMonth = (mDob.month() + 1).toString();
        this.dobDay = mDob.date().toString();
        this.dobYear = mDob.year().toString();
      }
      this.firstName = newVal.firstName;
      this.lastName = newVal.lastName;
      this.phone = newVal.phone;
      this.address1 = newVal.streetAddress1;
      this.address2 = newVal.streetAddress2;
      this.city = newVal.city;
      this.state = newVal.state;
      this.zip = newVal.zipCode;
      this.homeAddress1 = newVal.homeStreetAddress1;
      this.homeAddress2 = newVal.homeStreetAddress2;
      this.homeCity = newVal.homeCity;
      this.homeState = newVal.homeState;
      this.homeZip = newVal.homeZipCode;
      this.shippingIsSame = newVal.shippingIsSame;
    },
    getUpdatedData() {
      var p = Object.assign({}, this.viewModel.Patient);
      p.firstName = this.firstName;
      p.lastName = this.lastName;
      p.dateOfBirth = this.dateOfBirth;
      p.phone = this.phone;
      p.streetAddress1 = this.address1;
      p.streetAddress2 = this.address2;
      p.city = this.city;
      p.state = this.state;
      p.zipCode = this.zip;
      p.homeStreetAddress1 = this.homeAddress1;
      p.homeStreetAddress2 = this.homeAddress2;
      p.homeCity = this.homeCity;
      p.homeState = this.homeState;
      p.homeZipCode = this.homeZip;
      p.shippingIsSame = this.shippingIsSame;
      return p;
    },
    phone_validate(e: { isValid: any }) {
      this.phoneIsValid = e.isValid;
      //if (this.phone) this.validate("phone");
    },
    handleShippingIsSameClick(e: Event) {
      this.validateShippingAddress(e);
    },
    validateShippingAddress(e: boolean) {
      if (e) {
        this.checkSupportedState(this.homeState, (supported) => {
          if (supported) {
            this.homeAddress1 = this.address1;
            this.homeAddress2 = this.address2;
            this.homeCity = this.city;
            this.homeState = this.state;
            this.homeZip = this.zip;
          } else {
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          }
        });
      } else {
        this.homeAddress1 = "";
        this.homeAddress2 = "";
        this.homeCity = "";
        this.homeState = this.viewModel.PatientStatesMustMatch ? this.state : "";
        this.homeZip = "";
      }
    },
  },
  components: { VueTelInput },
});
