
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "photos-update-complete",

  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
  },
  methods: {
    next_click() {
      this.$emit("saveandnavigate", "+");
    },
  },
  components: { QuestWrapperComponent },
});
