
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import CategoryMenuComponent from "@/components/CategoryMenuComponent.vue";
import router from "@/router";
import Common from "@/mixins/Common";
import mixins from "vue-typed-mixins";

export default mixins(Common).extend({
  mixins: [Common],
  props: {
    id: { type: String, default: null },
    code: { type: String, default: null },
  },
  data() {
    return {
      isStepSelected: {
        section1: false,
        section2: false,
        section3: false,
      } as { [key: string]: boolean },
      activeSelected: "" as string,
      item: {} as QuestionnaireTypeProductViewModel,
      outsidePromo: null as string | null,
      legitScriptHtml: `
        <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
          <img src="https://static.legitscript.com/seals/17238074.png" alt="LegitScript approved" width="140" height="120" border="0" />
        </a>
      `,
      mainProductImage: "" as string,
    };
  },
  async created() {
    this.isStepSelected.section1 = true;
    this.activeSelected = "section1";
  },
  mounted() {
    this.getUTM();
    this.getPromoCode();
    this.scrollToTop();
    const defaultImage = this.Content.ProductImages[this.currentProgram.id][0];
    if (defaultImage) {
      this.mainProductImage = defaultImage.ProductImage;
    }
  },
  methods: {
    ...mapMutations({ setCurrentQuestType: "setCurrentQuestType", SetLoading: "setLoading" }),

    showSelected(section: string) {
      for (const key in this.isStepSelected) {
        this.isStepSelected[key] = false;
      }

      this.isStepSelected[section] = true;
      this.activeSelected = section;
    },
    GetStarted(t: number) {
      this.SetLoading(true);
      this.setCurrentQuestType(t as number);
      console.log(this.outsidePromo);
      if (this.outsidePromo !== null && this.outsidePromo.length > 0) {
        router.push("/questionnaire/?code=" + this.outsidePromo);
        console.log(this.outsidePromo);
      } else {
        router.push("/questionnaire");
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    selectProductImage(image: string) {
      this.mainProductImage = image;
    },
  },
  computed: {
    ...mapGetters({
      ShoppingCart: "getShoppingCart",
      SignedIn: "getSignedIn",
      getOTCProducts: "getOTCProducts",
      Content: "getContent",
      programs: "getQuestionnaireTypeProducts",
    }),
    QuestionnaireTypeProducts(): QuestionnaireTypeProductViewModel[] {
      return this.programs;
    },
    currentProgram(): QuestionnaireTypeProductViewModel {
      return this.programs.filter((x: QuestionnaireTypeProductViewModel) => x.id == parseInt(this.id))[0];
    },
  },
});
