
import Vue, { PropType } from "vue";
import { mapMutations, mapGetters } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import moment from "moment";

export default Vue.extend({
  name: "NotYetView",
  props: {
    q: Object as PropType<QuestViewModel>,
    screen: Number,
    show: Boolean,
    isUnsupportedState: Boolean,
    errors: Array,
  },
  created: function () {
    this.setLoading(false);
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    underAge(): boolean {
      return moment().diff(moment(this.q.patient.dateOfBirth, "YYYY=MM-DD"), "years", true) < 18;
    },
    femaleChosen(): boolean {
      return this.q.hardStopFemale && this.q.patient.gender !== "m";
    },
    hardStop(): boolean {
      return this.q.quest.hardStop || this.q.hardStop ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    hintLink_click() {
      console.log();
    },
  },
  components: { QuestWrapperComponent },
});
