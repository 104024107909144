
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import axios from "axios";
import store from "../store/index";
import router from "../router";

export default Vue.extend({
  name: "upsell-component",
  data() {
    return {
      selectedOption: "subscribe",
    };
  },
  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Theme: "getTheme",
      Content: "getContent",
      FirstName: "getFirstName",
      getOTCProducts: "getOTCProducts",
    }),
  },
  methods: {
    selectOption(option: string) {
      this.selectedOption = option;
    },
  },
});
