
import Vue from "vue";
import { mapGetters } from "vuex";
import store from "../store/index";
import router from "../router";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import { OTCDrugViewModel } from "@/interfaces/OTCDrugViewModel";
import { ProductPrice } from "@/interfaces/ProductPrice";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import { ShoppingCartViewModel } from "@/interfaces/ShoppingCartViewModel";
import { ProductTypeEnum } from "@/interfaces/ProductTypeEnum";
import axios from "axios";

export default Vue.extend({
  data() {
    return {
      item: {} as OTCProductViewModel,
      qty: 0,
      price: 0,
      isCustomQty: false,
      customQty: 0,
      customError: "",
    };
  },
  computed: {
    ...mapGetters({
      ShoppingCart: "getShoppingCart",
      SignedIn: "getSignedIn",
      getOTCProducts: "getOTCProducts",
    }),
    OTCProducts(): OTCProductViewModel[] {
      return this.getOTCProducts;
    },
  },
  async created() {
    await this.load(router.currentRoute.query.id, router.currentRoute.query.type);
  },
  watch: {
    async $route(nroute) {
      if (nroute.query && nroute.query.id && nroute.query.type) {
        await this.load(nroute.query.id, nroute.query.type);
      }
    },
  },
  methods: {
    async load(id: number, type: ProductTypeEnum) {
      console.log("get" + type);

      const dt: OTCProductViewModel = await this.getProductInfo(id, type);
      console.log(dt);
      this.item = dt;
      this.price = 0;
      this.qty = 0;
      this.isCustomQty = false;
      this.customQty = 0;
      this.customError = "";
    },
    async getProductInfo(id: number, type: ProductTypeEnum): Promise<OTCProductViewModel | null> {
      if (type == ProductTypeEnum.Drug) {
        const r = await axios.get("/api/Home/GetDrug", { params: { id: id } });
        return r.data;
      }
      if (type == ProductTypeEnum.Product) {
        const r = await axios.get("/api/Home/GetProduct", { params: { id: id } });
        return r.data;
      }
      return null;
    },
    selectQty_clicked(qty: number) {
      this.qty = qty;

      this.price = this.calcPrice(qty);
      this.isCustomQty = false;
      this.customQty = 0;
      this.customError = "";
    },
    calcPrice(qty: number): number {
      const pi = this.item.prices.findIndex((i: ProductPrice) => qty >= i.minQty && qty <= i.maxQty);

      if (pi < 0) {
        console.log("Invalid Price");
        return 0;
      }
      const basePrice = qty * this.item.prices[pi].pricePerUnit;
      const price = basePrice * this.item.prices[pi].markupPercentage;
      return price;
    },
    customQtyChanged() {
      const price = this.calcPrice(this.customQty);
      if (price === 0) {
        this.customError = "*Invalid quantity";
        return;
      }

      this.customError = "";
      this.isCustomQty = true;
      this.qty = this.customQty;
      this.price = price;
    },
    async addToCart() {
      if (this.qty === 0) {
        console.log("invalid qty selected");
        return;
      }
      var cart = this.ShoppingCart;
      if (!cart) {
        cart = {} as ShoppingCartViewModel;
        cart.items = [];
        //store.commit("setShoppingCart", cart);
      }

      const product: OTCProductViewModel = this.OTCProducts.find((i: OTCProductViewModel) => i.id == this.item.id && i.productType == this.item.productType);
      if (!product) {
        console.log("invalid product");
        return;
      }

      const id = this.ShoppingCart?.items?.length > 0 ? this.ShoppingCart?.items[this.ShoppingCart?.items?.length - 1].sid + 1 : 1;
      const pi = this.item.prices.findIndex((i: ProductPrice) => this.qty >= i.minQty && this.qty <= i.maxQty);

      const nitem: ShoppingCartItem = {
        sid: id,
        id: null,
        qty: this.qty,
        markup: this.item.prices[pi].markupPercentage,
        pricePerUnit: this.item.prices[pi].pricePerUnit,
        price: this.calcPrice(this.qty),
        productID: this.item.id,
        productTypeID: this.item.productType,
        active: true,
      };

      var okay = true;

      if (this.SignedIn) {
        const rsp = await store.dispatch("SaveShoppingCartItem", [nitem]);
        if (rsp) {
          console.log(rsp);
          const c = rsp.cart as ShoppingCartViewModel;
          c.visible = true;
          c.shown = true;
          store.commit("setShoppingCart", c);
        } else {
          console.log("error adding item to cart");
        }
        return;
      }

      if (okay) {
        if (cart?.items) {
          cart.items.push(nitem);
        } else {
          cart.items = [nitem];
        }
        cart.shown = true;
        cart.visible = true;
        store.commit("setShoppingCart", { ...cart });
        //this.ShoppingCart = store.dispatch("getShoppingCart");
        return;
      } else {
        console.log("error additem item");
      }
    },
  },
});
