import router from "@/router";
import store from "@/store";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";

export interface KeyValuePair {
  key: string;
  value: string;
}

export default Vue.extend({
  computed: {
    ...mapGetters({
      Role: "getRole",
      UTM: "getUTM",
    }),
  },
  methods: {
    fieldToTitleCase(evt: Event) {
      if (!evt || !evt.target) return;
      (evt.target as HTMLInputElement).value = this.toTitleCase((evt.target as HTMLInputElement).value);
    },
    toTitleCase(str: string) {
      return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    },
    async sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getTicks(dt: string | null): string {
      if (!dt) return "";
      return (new Date(dt).getTime() * 10000 + 621355968000000000).toString();
    },
    validateEmail(input: string): boolean {
      if (/[A-z,0-9,+]*@[A-z,0-9,+,.]*/.test(input)) {
        return true;
      } else {
        return false;
      }
    },
    getUTM() {
      const utm_source = router.currentRoute.query.utm_source?.toString();
      const utm_medium = router.currentRoute.query.utm_medium?.toString();
      const utm_campaign = router.currentRoute.query.utm_campaign?.toString();
      const utm_term = router.currentRoute.query.utm_term?.toString();
      const utm_content = router.currentRoute.query.utm_content?.toString();
      if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
        store.commit("setUTM", {
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_campaign: utm_campaign,
          utm_term: utm_term,
          utm_content: utm_content,
        });
      }
      this.submitUTM();
    },
    async submitUTM() {
      if (this.Role == "Patient" && this.UTM) {
        const r = await store.dispatch("UpdateUTM", this.UTM);
      }
    },
    getPromoCode() {
      const code = router.currentRoute.query.code?.toString();
      if (code) store.commit("setPassedPromoCode", code);
    },
    getLocalMomentDate(input: string): moment.Moment {
      const local = moment(moment.utc(input)).local();
      return local;
    },
  },
});
