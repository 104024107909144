
import router from "@/router";
import { mapMutations } from "vuex";
import mixins from "vue-typed-mixins";
import Common from "@/mixins/Common";

export default mixins(Common).extend({
  mixins: [Common],
  props: {
    questionnaireTypeID: { type: String, default: null },
    productRxID: { type: String, default: null },
    productRxSubscriptionFrequencyID: { type: String, default: null },
  },
  data() {
    return {};
  },
  mounted() {
    this.getUTM();
    this.getPromoCode();
    this.setLoading(false);
    this.OpenQuestionnaire(parseInt(this.questionnaireTypeID));
  },
  watch: {
    questionnaireTypeID() {
      this.OpenQuestionnaire(parseInt(this.questionnaireTypeID));
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setCurrentQuestType: "setCurrentQuestType",
    }),
    OpenQuestionnaire(t: number) {
      this.setCurrentQuestType(t);
      router.push({ path: "/questionnaire", query: { p: this.productRxID, s: this.productRxSubscriptionFrequencyID, tsf: 24 } });
    },
  },
});
