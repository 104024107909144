
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import { TwoFactorTypeEnum } from "@/interfaces/TwoFactorTypeEnum";

enum screenview {
  selectMethod = 0,
  apptoken = 1,
  emailtoken = 2,
  smstoken = 3,
}
export default mixins(CustomValidation, common).extend({
  name: "two-factor",
  props: {
    autoSend: Boolean,
    token: String,
    authenticator: Boolean,
    authMethod: Number,
    authMethods: [],
    codeOnly: Boolean,
    isForgotPassword: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      expireAtEpoch: 0,
      view: 0 as screenview | 0,
      method: 0 as TwoFactorTypeEnum | 0,
      code: null as string | null,
      firstdigit: "" as string | "",
      seconddigit: "" as string | "",
      thirddigit: "" as string | "",
      fourthdigit: "" as string | "",
      fifthdigit: "" as string | "",
      sixthdigit: "" as string | "",
    };
  },
  watch: {
    authMethod(nv: TwoFactorTypeEnum) {
      this.init(nv);
    },
  },
  async mounted() {
    this.init(this.authMethod);
    // https://stackoverflow.com/a/69026004
    // https://stackoverflow.com/questions/56093602/cant-get-vue-to-focus-on-input
    setTimeout(() => {
      this.$nextTick(() => {
        if (this.$refs.firstd) {
          this.$refs.firstd.focus();
        }
      });
    }, 500);
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async init(m: TwoFactorTypeEnum) {
      this.reset();
      switch (m) {
        case TwoFactorTypeEnum.App:
          this.view = screenview.apptoken;
          this.method = TwoFactorTypeEnum.App;
          break;
        case TwoFactorTypeEnum.Email:
        case TwoFactorTypeEnum.SMS:
          this.setLoading(true);
          this.view = m == TwoFactorTypeEnum.Email ? screenview.emailtoken : screenview.smstoken;
          this.method = m;
          if (this.autoSend == null || this.autoSend) {
            await store.dispatch("TwoFactorToken", { method: m, token: this.token });
          }
          this.setLoading(false);
          break;
        default:
          //if (this.method != TwoFactorTypeEnum.Email && !this.authMethods?.includes(TwoFactorTypeEnum.SMS)) {
          //  await this.init(TwoFactorTypeEnum.Email);
          //  return;
          //}
          this.view = screenview.selectMethod;
          this.method = 0;
          break;
      }
    },
    async btnNoApp(e: Event) {
      e.preventDefault();
      await this.init(TwoFactorTypeEnum.None);
    },
    inputPaste(e: Event) {
      e.preventDefault();
      const data = e.clipboardData.getData("text");
      if (data.length == 6) {
        this.firstdigit = data[0];
        this.seconddigit = data[1];
        this.thirddigit = data[2];
        this.fourthdigit = data[3];
        this.fifthdigit = data[4];
        this.sixthdigit = data[5];

        const code = this.calcCode();
        this.$emit("codeChange", code);
      }
    },
    inputChange(e: Event) {
      e.preventDefault();
      const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const bspace = e.keyCode == 8 ? true : false;
      let validkey = true;
      if (!keys.includes(e.key) && e.keyCode != 8) {
        validkey = false;
      }
      var current = null;
      var target = null;
      switch (e.target) {
        case this.$refs.firstd:
          if (!bspace) {
            target = this.$refs.secondd;
          }
          current = this.$refs.firstd;
          break;
        case this.$refs.secondd:
          if (!bspace) {
            target = this.$refs.thirdd;
          } else {
            target = this.$refs.firstd;
          }
          current = this.$refs.secondd;
          break;
        case this.$refs.thirdd:
          if (!bspace) {
            target = this.$refs.fourthd;
          } else {
            target = this.$refs.secondd;
          }
          current = this.$refs.thirdd;
          break;
        case this.$refs.fourthd:
          if (!bspace) {
            target = this.$refs.fifthd;
          } else {
            target = this.$refs.thirdd;
          }
          current = this.$refs.fourthd;
          break;
        case this.$refs.fifthd:
          if (!bspace) {
            target = this.$refs.sixthd;
          } else {
            target = this.$refs.fourthd;
          }
          current = this.$refs.fifthd;
          break;
        case this.$refs.sixthd:
          if (bspace) {
            target = this.$refs.fifthd;
          }
          current = this.$refs.sixthd;
          break;
        default:
          break;
      }
      if (target && validkey && !bspace) {
        target.focus();
        target.select();
        return;
      }
      if (e.key == " " && current && !bspace) {
        current.value = "";
      }
      if (current && target && bspace && current.value == "") {
        target.focus();
        target.select();
      }
      const code = this.calcCode();
      if (code.length == 6) {
        this.$emit("codeChange", code);
      }
    },
    calcCode() {
      return this.firstdigit + this.seconddigit + this.thirddigit + this.fourthdigit + this.fifthdigit + this.sixthdigit;
    },
    async onSubmitMFA(event: { preventDefault: () => void }) {
      event.preventDefault();
      this.code = this.firstdigit + this.seconddigit + this.thirddigit + this.fourthdigit + this.fifthdigit + this.sixthdigit;
      this.firstdigit = "";
      this.seconddigit = "";
      this.thirddigit = "";
      this.fourthdigit = "";
      this.fifthdigit = "";
      this.sixthdigit = "";

      this.$emit("submitMFA", this.code, this.method);
    },
    reset() {
      this.code = "";
      this.firstdigit = "";
      this.seconddigit = "";
      this.thirddigit = "";
      this.fourthdigit = "";
      this.fifthdigit = "";
      this.sixthdigit = "";
    },
    async btnSelectMethod_click(e) {
      this.init(this.method);
    },
    async resend2fa() {
      // logic to handle additional submissions within five minutes
      const currentTime = Date.now();
      if (this.expireAtEpoch && currentTime < this.expireAtEpoch) {
        this.$bvToast.toast(`Please wait five minutes prior to requesting an additional code.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "warning" });
      } else {
        this.expireAtEpoch = Date.now() + 5 * 60 * 1000;

        if (this.isForgotPassword) {
          this.$emit("forgotPasswordBool", true);
        } else {
          const result = await store.dispatch("TwoFactorToken", { method: this.view, token: this.token });
          if (result) {
            this.$bvToast.toast(`New verification code was sent.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "success" });
          } else {
            this.$bvToast.toast(`Unable to send new verification code, please contact development team.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "Warning" });
          }
        }
      }
    },
  },
});
