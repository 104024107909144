
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { object } from "yup";
import { mapGetters } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { ProductRxGroup } from "../../interfaces/ProductRxGroup";
import { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { ProductRxSubscriptionFrequencyViewModel } from "@/interfaces/ProductRxSubscriptionFrequencyViewModel";
import store from "@/store";
import { ModifyViewModel } from "@/interfaces/ModifyViewModel";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import router from "@/router";

export default mixins(CustomValidation).extend({
  mixins: [CustomValidation],
  name: "choose-quantity",
  props: {
    q: Object as PropType<ModifyViewModel>,
    isModify: { type: Boolean, default: true },
    selectedProductSubscriptionAvailable: { default: false, type: Boolean },
    selectedFrequency: { default: null, type: Object },
  },
  data() {
    return {
      schema: object().shape({
        selectedProduct: object().typeError("Product is required.").required("Product is required."),
      }),
      selectedProductGroup: null as ProductRxGroup | null,
      selectedProduct: null as AffiliateProductRx | null,
      selectedProductTypeID: 1,
      isSubscription: false as boolean,
      isSubscriptionDiscountAvailable: false,
      fillSubscriptionFrequencyDays: null as number | null,
      chargeSubscriptionFrequencyDays: null as number | null,
      freqs: [] as ProductRxSubscriptionFrequencyViewModel[],
    };
  },
  mounted() {
    this.setupValidation(this.schema);
    this.importData(this.q.quest);
  },
  watch: {
    q(newVal: ModifyViewModel) {
      this.importData(newVal.quest);
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    stepType(): string {
      return this.isModify ? "modify" : "new";
    },
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    productGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    filteredProducts(): Array<AffiliateProductRx> {
      var result = [] as Array<AffiliateProductRx>;
      if (!this.selectedProductGroup) return result;
      for (var i = 0; i < this.products.length; i++) {
        //&& (!this.isSubscription || (this.isSubscription && !this.products[i].isIntroductory))
        if (this.products[i].productRxGroupID === this.selectedProductGroup.id) {
          result.push(this.products[i]);
        }
      }
      return result.sort(function (a, b) {
        return a.fillQty - b.fillQty;
      });
    },
    nextText(): string {
      return "Continue";
    },
  },
  methods: {
    async importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      //var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      var qProductRx = null;
      if (newVal.qProductRxs && newVal.qProductRxs.length > 0) {
        var qprs = newVal.qProductRxs.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
        if (qprs && qprs.length > 0) {
          qProductRx = qprs[0];
        }
      }
      // Handle case when next titration is chosen and the user returns to the modify screen
      if (qProductRx == null) {
        qProductRx = Object.assign({}, this.q.currentQuestionnaireProductRx);
        qProductRx.questionnaireID = this.q.quest.id;
      }
      this.selectedProductGroup = null;
      this.selectedProduct = null;
      this.isSubscriptionDiscountAvailable = false;
      for (var i = 0; i < this.productGroups.length; i++) {
        if (qProductRx && this.productGroups[i].id === qProductRx.productRxGroupID) {
          this.selectedProductGroup = this.productGroups[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        var freqs = await store.dispatch("GetFreqsByProductRxID", this.products[i].id);
        freqs.forEach((x: ProductRxSubscriptionFrequencyViewModel) => {
          if (this.freqs.findIndex((y) => y.chargeSubscriptionFrequencyID == x.chargeSubscriptionFrequencyID && y.fillSubscriptionFrequencyID == x.fillSubscriptionFrequencyID) == -1) {
            this.freqs.push(x);
          }
        });
        if (qProductRx && this.products[i].id === qProductRx.productRxID) {
          this.selectedProduct = this.products[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (this.selectedProductGroup && this.products[i].productRxGroupID === this.selectedProductGroup.id) {
          if (this.selectedProduct == null) {
            this.selectedProduct = this.products[i];
          }
          var p = this.products[i] as AffiliateProductRx;
          if (p && p.subscriptionProgramPrice && p.subscriptionProgramPrice > 0) {
            this.isSubscriptionDiscountAvailable = true;
          }
        }
      }
      this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isSubscription = qProductRx ? qProductRx.isSubscription : false;
      this.fillSubscriptionFrequencyDays = qProductRx ? qProductRx.fillSubscriptionFrequencyDays : this.fillSubscriptionFrequencyDays;
      this.chargeSubscriptionFrequencyDays = qProductRx ? qProductRx.chargeSubscriptionFrequencyDays : this.chargeSubscriptionFrequencyDays;
    },
    pgStyle(p: AffiliateProductRx) {
      if (this.selectedProduct == p)
        if (p.isIntroductory) return "border: 1px solid #000000 !important; background-color: #f7f7f7 !important;";
        else return "border: 1px solid #000000 !important; background-color: #f7f7f7 !important;";
      else return "";
    },
    getFrequency(qpr: QuestionnaireProductRx): ProductRxSubscriptionFrequencyViewModel | null {
      console.log("getFrequency");
      console.log(qpr);
      console.log(this.freqs);
      var results = this.freqs.filter((y) => y.chargeSubscriptionFrequencyDays == qpr.chargeSubscriptionFrequencyDays && y.fillSubscriptionFrequencyDays == qpr.fillSubscriptionFrequencyDays);
      if (results && results.length > 0) {
        console.log(results);
        return results[0];
      }
      return null;
    },
    setProduct(p: AffiliateProductRx, submitNow?: boolean) {
      this.selectedProduct = p;
      console.log("selected was set = " + p);
      if (submitNow) {
        this.next_click();
      }
    },
    getUpdatedData(selectedProductSubscriptionAvailable: boolean): QuestionnaireViewModel {
      if (!this.selectedProduct) return this.q.quest;
      var q = Object.assign({}, this.q.quest);
      //var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
      var qProductRx = {} as QuestionnaireProductRx;
      if (q.qProductRxs && q.qProductRxs.length > 0) {
        var qprs = q.qProductRxs.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
        if (qprs && qprs.length > 0) {
          qProductRx = qprs[0];
        }
      } // Save other qprs
      var otherQPRs = q.qProductRxs.filter((x) => !x.productRxGroupID);

      // Setup main qpr
      qProductRx.productRxID = this.selectedProduct.id;
      qProductRx.productTypeID = this.selectedProductTypeID;
      qProductRx.isSubscription = this.isSubscription;
      qProductRx.fillSubscriptionFrequencyDays = this.fillSubscriptionFrequencyDays;
      qProductRx.chargeSubscriptionFrequencyDays = this.chargeSubscriptionFrequencyDays;
      qProductRx.active = true;
      qProductRx.productRxGroupID = this.selectedProduct.productRxGroupID;
      q.qProductRxs = [qProductRx];
      if (this.selectedProduct.isIntroductory || !selectedProductSubscriptionAvailable) qProductRx.isSubscription = false;

      // Add other qprs back with same subscription
      otherQPRs.forEach((x) => {
        x.fillSubscriptionFrequencyDays = this.fillSubscriptionFrequencyDays;
        x.chargeSubscriptionFrequencyDays = this.chargeSubscriptionFrequencyDays;
        x.numberOfSubscriptionChargesRequired = qProductRx.numberOfSubscriptionChargesRequired;
        x.isSubscription = qProductRx.isSubscription;
        q.qProductRxs.push(x);
      });
      return q;
    },
    async getFreqsByProductID(id: number, cb: (arg0: boolean) => void) {
      var freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (freqs) {
        if (freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
          cb(true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
          cb(false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
        cb(false);
      }
    },
    cancel_click() {
      //router.push("/patientportal");
      if (!this.selectedProduct || !this.selectedProduct.id) return;
      this.getFreqsByProductID(this.selectedProduct.id, (selectedProductSubscriptionAvailable) => {
        if (!this.selectedProduct) return;
        var skipShipping = false;
        if (this.selectedProduct.isIntroductory || !selectedProductSubscriptionAvailable) {
          skipShipping = true;
          this.isSubscription = false;
        }
        if (!this.isSubscription) {
          skipShipping = true;
          this.fillSubscriptionFrequencyDays = null;
          this.$emit("update:selectedFrequency", null);
        }
        console.log("saveandnavigate");
        this.$emit("saveandnavigate", "-", this.getUpdatedData(selectedProductSubscriptionAvailable), skipShipping);
      });
    },
    next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          console.log(this.selectedProduct);
          if (!this.selectedProduct || !this.selectedProduct.id) return;
          this.getFreqsByProductID(this.selectedProduct.id, (selectedProductSubscriptionAvailable) => {
            if (!this.selectedProduct) return;
            var skipShipping = false;
            if (this.selectedProduct.isIntroductory || !selectedProductSubscriptionAvailable) {
              skipShipping = true;
              this.isSubscription = false;
            }
            if (!this.isSubscription) {
              skipShipping = true;
              this.fillSubscriptionFrequencyDays = null;
              this.$emit("update:selectedFrequency", null);
            }
            console.log("saveandnavigate");
            this.$emit("saveandnavigate", "+", this.getUpdatedData(selectedProductSubscriptionAvailable), skipShipping);
          });
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
            console.log(error.message);
          });
          console.log(this.errors);
        });
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getUpdatedData(this.isSubscription));
    },
    getCurrentDescription(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ModifyCurrentDoseBlurb;
      }
      return null;
    },
    getModifyDescription(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ModifyIncreaseDoseBlurb;
      }
      return null;
    },
  },
  components: { QuestWrapperComponent },
});
