
import Vue from "vue";
import EasyCamera from "easy-vue-camera";

export interface IEasyCamera extends Element {
  stop: () => void;
  start: () => void;
  snap: () => void;
  switchCamera: () => void;
  multiDevice: boolean;
}

export default Vue.extend({
  name: "camera-vue-component",
  props: {
    value: String,
  },
  data() {
    return {
      picture: "",
      takeMode: true,
      multiDevice: false,
    };
  },
  mounted() {
    window.setTimeout(() => {
      try {
        this.multiDevice = (this.$refs.cam as IEasyCamera).multiDevice;
      } catch (e: any) {
        window.setTimeout(() => {
          try {
            this.multiDevice = (this.$refs.cam as IEasyCamera).multiDevice;
          } catch (e: any) {
            console.log(e);
          }
        }, 1500);
      }
    }, 500);
  },
  watch: {
    picture(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("update:value", newVal);
      }
    },
  },
  methods: {
    takePic() {
      (this.$refs.cam as IEasyCamera).snap();
      (this.$refs.cam as IEasyCamera).stop();
      this.takeMode = false;
    },
    changePic() {
      this.takeMode = true;
      window.setTimeout(() => {
        (this.$refs.cam as IEasyCamera).start();
      }, 100);
    },
    switchCam() {
      (this.$refs.cam as IEasyCamera).switchCamera();
    },
  },
  components: { EasyCamera },
});
