
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { mapMutations, mapGetters } from "vuex";
import PromoPricing from "@/mixins/PromoPricing";
import mixins from "vue-typed-mixins";
import store from "@/store";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { ProductRx } from "@/interfaces/ProductRx";

export default mixins(PromoPricing).extend({
  mixins: [PromoPricing],
  name: "charge-confirmation",
  props: {
    onlineDoctorConsultFee: { type: Number, default: 0 },
  },
  async mounted() {
    this.importData(this.q.quest);
    var codePromo = await store.dispatch("GetPromoByCode", { t: this.q.quest.questionnaireTypeID, code: this.q.quest.promoCode, notInitialQuest: false });
    var qProductRx = this.q.quest.qProductRxs && this.q.quest.qProductRxs.length > 0 ? this.q.quest.qProductRxs[0] : { id: 0, isSubscription: false };
    this.mixinAdjustedDoctorConsultfee(this.isPayment, this.isRefill, this.q, this.mixinGetPromoProd(this.q.everyonePromo, qProductRx.id, qProductRx.isSubscription), this.mixinGetPromoProd(codePromo, qProductRx.id, qProductRx.isSubscription));
    this.setLoading(false);
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
  },
  watch: {
    q(newVal) {
      this.importData(newVal.quest);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
            break;
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as ProductRx[];
      }
      this.isExpeditedShipping = newVal.isExpeditedShipping;
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.outsidePromo !== null) {
        newVal.promoCode = this.outsidePromo;
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.PassedPromoCode !== null) {
        newVal.promoCode = this.PassedPromoCode;
      }
      if (this.promoCode != newVal.promoCode) {
        this.promoCode = newVal.promoCode;
        if (this.promoCode && this.promoCode.length > 0)
          this.GetPromoByCode(this.promoCode, null, () => {
            this.promoCode = null;
          });
      }
      this.isSubscription = qProductRx ? qProductRx.isSubscription : this.isSubscription;
    },
    next_click() {
      this.$emit("saveandnavigate", "+");
    },
    prev_click() {
      this.$emit("saveandnavigate", "-");
    },
  },
  components: { QuestWrapperComponent },
});
