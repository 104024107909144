
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import Calendar from "primevue/calendar";
import "primevue/resources/themes/md-light-deeppurple/theme.css";
import "primevue/resources/primevue.min.css";
import InputText from "primevue/inputtext";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import Slider from "primevue/slider";

enum screenview {
  trackerDash = 0,
  updateWeight = 1,
  motivation = 2,
}

interface WeightLog {
  weight: number;
  date: Date;
  previousWeightDifference: number | null;
}

export default mixins(CustomValidation, common).extend({
  name: "weight-tracker",
  props: {
    showFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      view: screenview.trackerDash,
      startingWeight: 195 as number,
      goalWeight: 170 as number,
      currentWeight: 180 as number,
      newWeight: 180 as number | null,
      weightProgress: null as number | null,
      weightDifference: null as number | null,
      newWeightDate: new Date(),
      chart: null as ECharts | null,
      weightLogs: [] as WeightLog[],
    };
  },
  computed: {
    textColorClass() {
      return this.showFull ? "text-dark" : "text-white";
    },
  },
  methods: {
    openUpdateWeight() {
      this.view = screenview.updateWeight;
    },
    updateWeight() {
      if (this.newWeight !== null && !isNaN(this.newWeight)) {
        let difference: number | null = null;
        if (this.weightLogs.length > 0) {
          // Calculate difference from the last entry
          const lastLog = this.weightLogs[this.weightLogs.length - 1];
          difference = parseFloat((this.newWeight - lastLog.weight).toFixed(1));
        }

        this.weightLogs.push({
          weight: this.newWeight,
          date: new Date(this.newWeightDate),
          previousWeightDifference: difference,
        });
      }
      if (this.newWeight) {
        this.currentWeight = this.newWeight;
      }
      console.log("Array:", this.weightLogs);
      this.weightProgressCalc();

      this.view = screenview.motivation;
    },
    confirmMotivation() {
      this.view = screenview.trackerDash;
      this.$nextTick(() => {
        this.initChart();
      });
    },
    weightProgressCalc() {
      let currentWeight = this.currentWeight;
      let startingWeight = this.startingWeight;
      let goalWeight = this.goalWeight;

      let weightProgress = ((startingWeight - currentWeight) / (startingWeight - goalWeight)) * 100;

      this.weightDifference = parseFloat((startingWeight - currentWeight).toFixed(2));

      this.weightProgress = weightProgress;
      console.log("Progress Percent:", this.weightProgress, "Weight Lossed:", this.weightDifference);
    },
  },
  components: {
    Calendar,
    InputText,
    Slider,
  },
});
