export enum FolderTypeEnum {
  Support = 0,
  ED = 1,
  Vuity = 2,
  WoundCare = 3,
  OnBoard = 4,
  PeptideWeightLoss = 5,
  Pharmacy = 999,
  HairLoss = 6,
  Ptosis = 7,
  FemaleArousal = 8,
  Ondansetron = 11,
  Bimatoprost = 12,
}
