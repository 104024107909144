
import Vue from "vue";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { ProductRxGroup } from "../../interfaces/ProductRxGroup";
import { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { ProductRxSubscriptionFrequencyViewModel } from "../../interfaces/ProductRxSubscriptionFrequencyViewModel";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import store from "@/store";
import { BIcon } from "bootstrap-vue";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";

export default Vue.extend({
  name: "choose-shipping",
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    selectedFrequency: { default: null, type: Object },
    paymentOnly: { type: Boolean, default: false },
    selectedProductSubscriptionAvailable: { default: false, type: Boolean },
  },
  data() {
    return {
      selectedProductRxGroup: null as ProductRxGroup | null,
      selectedProduct: null as AffiliateProductRx | null,
      selectedProductTypeID: 1,
      isSubscription: false as boolean,
      freqs: [] as Array<ProductRxSubscriptionFrequencyViewModel>,
      selectedFreq: null as ProductRxSubscriptionFrequencyViewModel | null,
      fillSubscriptionFrequencyDays: null as number | null,
      chargeSubscriptionFrequencyDays: null as number | null,
      numberOfSubscriptionChargesRequired: null as number | null,
    };
  },
  created() {
    this.importData(this.q.quest);
  },
  watch: {
    q(newVal) {
      this.importData(newVal);
    },
    selectedFreq(newVal) {
      console.log("selectedFreq");
      console.log(newVal);
      this.$emit("update:selectedFrequency", newVal);
    },
    isSubscriptionAvailable(newVal) {
      if (!newVal) this.isSubscription = false;
    },
  },
  computed: {
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    ProductRxGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    nextText(): string {
      return "CONTINUE";
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : "new";
    },
    isSubscriptionAvailable(): boolean {
      if (this.freqs && this.freqs.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal || !newVal.qProductRxs || newVal.qProductRxs.length == 0) return;
      var qProductRx = newVal.qProductRxs[0];

      for (var i = 0; i < this.ProductRxGroups.length; i++) {
        if (this.ProductRxGroups[i].id === qProductRx.productRxGroupID) {
          this.selectedProductRxGroup = this.ProductRxGroups[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (this.products[i].id === qProductRx.productRxID) {
          this.selectedProduct = this.products[i];
          break;
        }
      }
      if (this.selectedProduct == null) {
        this.selectedProduct = {} as AffiliateProductRx;
      }
      this.selectedProductTypeID = qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.fillSubscriptionFrequencyDays = qProductRx.fillSubscriptionFrequencyDays;
      this.chargeSubscriptionFrequencyDays = qProductRx.chargeSubscriptionFrequencyDays;
      this.numberOfSubscriptionChargesRequired = qProductRx.numberOfSubscriptionChargesRequired;
      this.isSubscription = qProductRx.isSubscription;
      if (!this.selectedProduct.id) return;
      this.GetFreqsByProductRxID(this.selectedProduct.id, () => {
        for (var i = 0; i < this.freqs.length; i++) {
          if (this.freqs[i].fillSubscriptionFrequencyDays == qProductRx.fillSubscriptionFrequencyDays && this.freqs[i].chargeSubscriptionFrequencyDays == qProductRx.chargeSubscriptionFrequencyDays) {
            this.setSubscription(this.freqs[i]);
          }
        }
        if (this.isSubscription == true && !this.selectedFreq && this.freqs) {
          this.setSubscription(this.freqs[0]);
        } else {
          if (this.selectedFreq) this.setSubscription(this.selectedFreq);
        }
      });
    },
    setSubscription(subFreq: ProductRxSubscriptionFrequencyViewModel | null) {
      this.selectedFreq = subFreq;
      this.fillSubscriptionFrequencyDays = this.selectedFreq && this.selectedFreq.fillSubscriptionFrequencyDays > 0 ? this.selectedFreq.fillSubscriptionFrequencyDays : null;
      this.chargeSubscriptionFrequencyDays = this.selectedFreq && this.selectedFreq.chargeSubscriptionFrequencyDays > 0 ? this.selectedFreq.chargeSubscriptionFrequencyDays : null;
      this.numberOfSubscriptionChargesRequired = this.selectedFreq && this.selectedFreq.numberOfSubscriptionChargesRequired > 0 ? this.selectedFreq.numberOfSubscriptionChargesRequired : null;
      this.isSubscription = this.fillSubscriptionFrequencyDays && this.fillSubscriptionFrequencyDays > 0 ? true : false;
    },
    subStyle(subFreq: ProductRxSubscriptionFrequencyViewModel | null) {
      if ((!subFreq && !this.selectedFreq) || (subFreq && this.selectedFreq && subFreq.id == this.selectedFreq.id))
        if (subFreq) return "border: 1px solid gold !important";
        else return "border: 1px solid #3E71C0 !important";
      else if (!subFreq) return "filter: opacity(.5) grayscale(1)";
      else return "";
    },
    async GetFreqsByProductRxID(id: number, cb: (arg0: boolean) => void) {
      this.freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (this.freqs) {
        if (this.freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
          cb(true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
          cb(false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
        cb(false);
      }
    },
    getUpdatedData() {
      var q = Object.assign({}, this.q.quest);
      var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
      qProductRx.fillSubscriptionFrequencyDays = this.fillSubscriptionFrequencyDays;
      qProductRx.chargeSubscriptionFrequencyDays = this.chargeSubscriptionFrequencyDays;
      qProductRx.numberOfSubscriptionChargesRequired = this.numberOfSubscriptionChargesRequired;
      qProductRx.isSubscription = this.isSubscription;
      q.qProductRxs = [qProductRx];
      if (this.isSubscription) {
        q.userAllowsChargeAndFillUponApproval = true;
      }
      return q;
    },
    next_click() {
      this.$emit("saveandnavigate", "+", this.getUpdatedData());
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getUpdatedData());
    },
  },
  components: { QuestWrapperComponent, BIcon },
});
