
import mixins from "vue-typed-mixins";
import CustomValidation from "../mixins/CustomValidation";
import Common from "../mixins/Common";
import Vue, { set } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { number, object, string, boolean } from "yup";
import router from "@/router";
import axios from "axios";
import store from "@/store";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { VueTelInput } from "vue-tel-input";
import swal from "sweetalert";
import { TwoFactorTypeEnum } from "@/interfaces/TwoFactorTypeEnum";
import TwoFactorComponent from "@/components/TwoFactorComponent.vue";
enum screen {
  reset = 0,
  authenticator = 1,
}

export default mixins(CustomValidation, Common).extend({
  mixins: [CustomValidation, Common],
  data() {
    return {
      expireAtEpoch: 0,
      code: null as string | null,
      validated: false,
      error: "" as string | "",
      loading: false,
      eightChars: false,
      oneNumber: false,
      lowerCase: false,
      upperCase: false,
      match: false,
      enableSubmit: false,
      enableForgotPassword: true,
      enableResetPassword: true,
      submitted: false,
      forgotSubmitted: false,
      email: "" as string | "",
      password: "" as string | "",
      confirmPassword: "" as string | "",
      schemaReset: object().shape({
        password: string().typeError("Password is required.").required("Password is required."),
        confirmPassword: string().typeError("Password is required.").required("Password is required."),
        email: string().typeError("Email is required").email("The Email field is not a valid e-mail address.").required("Email is required"),
      }),
      schemaForgot: object().shape({
        email: string()
          .typeError("Email or Phone Number is required")
          .matches(RegExp(/^(?:\d{10,12}|[a-zA-Z-_+.0-9]{2,30}@[a-zA-Z0-9_-]{2,30}\.\w{2,3})$/), "The field does not contain a valid Email or Phone Number.")
          .required("Email or PhoneNumber is required"),
      }),
      twoFactorEnabled: false,
      authenticatorEnabled: false,
      authMethod: 0 as TwoFactorTypeEnum | 0,
      token: "",
      view: 0 as screen | 0,
      isEmail: false as boolean,
      isPhone: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      SignedIn: "getSignedIn",
      Affiliate: "getAffiliate",
    }),
  },
  watch: {
    password: function () {
      this.validatePassword();
    },
    confirmPassword: function () {
      this.validatePassword();
    },
  },
  async mounted() {
    //this.setLoading(true);
    this.init();
    this.code = router.currentRoute.query.code?.toString();
    if (this.code == "") {
      this.setupValidation(this.schemaReset);
    } else {
      this.setupValidation(this.schemaForgot);
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async init() {
      if (this.SignedIn) {
        const settings = await store.dispatch("GetAccountSettings");
        if (settings?.data) {
          if (settings.data.identityProviderID != null) {
            this.enableForgotPassword = false;
            this.enableResetPassword = false;
          }
        }
      }
    },
    sendForgot() {
      // logic to handle additional submissions within five minutes
      const currentTime = Date.now();
      if (this.expireAtEpoch && currentTime < this.expireAtEpoch) {
        this.$bvToast.toast(`Please wait five minutes prior to requesting an additional code.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "warning" });
      } else {
        this.expireAtEpoch = Date.now() + 5 * 60 * 1000;

        if (!this.enableForgotPassword) {
          return;
        }
        this.schemaForgot
          .validate(this, { abortEarly: false })
          .then(async () => {
            this.error = "";
            this.setLoading(true);
            const rsp = await store.dispatch("UserForgotPassword", { username: this.email, token: "" });
            if (rsp?.success) {
              this.$bvToast.toast(`New verification code was sent.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "success" });
              if (this.isPhone) {
                this.authMethod = TwoFactorTypeEnum.SMS;
              } else {
                this.authMethod = TwoFactorTypeEnum.Email;
              }
              this.forgotSubmitted = true;
              this.view = screen.authenticator;
            } else {
              this.$bvToast.toast(`Unable to send new verification code, please contact development team.`, { title: "Message", autoHideDelay: 3000, appendToast: true, variant: "Warning" });
              this.error = rsp.errors[0];
            }
            this.setLoading(false);
          })
          .catch((err: { inner: any[] }) => {
            this.setLoading(false);
          });
      }
    },
    async onSubmitMFA(code: string, method: TwoFactorTypeEnum) {
      //await this.completePasswordReset(code, method);
      const rsp = await store.dispatch("UserForgotPassword", { username: this.email, token: code });
      if (rsp.success) {
        this.code = rsp.data;
        this.view = screen.reset;
      } else {
        swal("Invalid code.");
      }
    },
    async resetPassword() {
      if (!this.enableResetPassword) {
        return;
      }
      if (!this.enableSubmit) {
        return;
      }

      //const response = await store.dispatch("UserIs2FA", this.code);
      //if (!response) {
      //  return;
      //}

      //if (response.twoFactorEnabled) {
      //  this.twoFactorEnabled = true;
      //  this.token = response.token;
      //  this.authenticatorEnabled = response.authenticatorEnabled;
      //  this.authMethod = response.authenticatorEnabled ? TwoFactorTypeEnum.App : TwoFactorTypeEnum.Email;
      //  this.view = screen.authenticator;
      //  return;
      //}

      await this.completePasswordReset("", 0);
    },
    async completePasswordReset(authCode: string, method: TwoFactorTypeEnum) {
      this.error = "";
      this.setLoading(true);
      this.loading = true;

      const rsp = await store.dispatch("UserPasswordReset", { code: this.code, newPassword: this.password, c: authCode, m: method });
      this.setLoading(false);
      if (rsp.errors && rsp.errors.length > 0) {
        if (rsp.errors[0] == "Invalid two factor code") {
          swal("Authentication Failed", rsp.errors[0], "error");
        }
        if (rsp.errors[0] == "Invalid reset code. A new reset code has been set. Please check your email.") {
          swal("Password reset failed.", rsp.errors[0], "error");
        }
        return;
      }
      this.view = screen.reset;
      this.submitted = true;

      if (rsp?.data) {
        this.validated = true;
      } else {
        this.error = rsp.errors[0];
        this.loading = false;
        return;
      }
      swal("Reset Success", "Password reset successfully, You can now login with your new password.");
      router.push("/signin");
    },
    validatePassword: function () {
      this.enableSubmit = false;

      if (this.password && this.password.length > 7) {
        this.eightChars = true;
      } else {
        this.eightChars = false;
      }
      var rgxON = RegExp("[0-9]");
      if (this.password && rgxON.test(this.password)) {
        this.oneNumber = true;
      } else {
        this.oneNumber = false;
      }
      var lcRGX = RegExp("[a-z]");
      if (this.password && lcRGX.test(this.password)) {
        this.lowerCase = true;
      } else {
        this.lowerCase = false;
      }
      var ucRGX = RegExp("[A-Z]");
      if (this.password && ucRGX.test(this.password)) {
        this.upperCase = true;
      } else {
        this.upperCase = false;
      }
      if (this.password && this.confirmPassword && this.password == this.confirmPassword) {
        this.match = true;
      } else {
        this.match = false;
      }

      if (this.eightChars && this.oneNumber && this.lowerCase && this.upperCase && this.match) {
        this.enableSubmit = true;
      }
    },
    checkIfEmail() {
      const emailRegex = /^(?:[a-zA-Z-_+.0-9]{2,30}@[a-zA-Z0-9_-]{2,30}\.\w{2,3})$/;
      const phoneRegex = /^\d{10,12}$/;

      if (this.email === "") {
        this.isEmail = false;
        this.isPhone = false;
        return;
      }

      if (emailRegex.test(this.email)) {
        this.isEmail = true;
        this.isPhone = false;
        return;
      } else if (phoneRegex.test(this.email)) {
        this.isEmail = false;
        this.isPhone = true;
        return;
      } else {
        this.isEmail = false;
        this.isPhone = false;
        return;
      }
    },
  },
  components: {
    TwoFactorComponent,
    VueTelInput,
  },
});
