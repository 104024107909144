
import { BContainer, BForm, BFormInput, BButton, BRow, BCol } from "bootstrap-vue";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import swal from "sweetalert";
import { mapGetters, mapMutations } from "vuex";
import { ShoppingCartViewModel } from "@/interfaces/ShoppingCartViewModel";
import { TwoFactorTypeEnum } from "@/interfaces/TwoFactorTypeEnum";
import TwoFactorComponent from "@/components/TwoFactorComponent.vue";
enum screen {
  Signin = 0,
  authenticator = 1,
}
import axios from "axios";
import { data } from "jquery";
import { object } from "yup/lib/locale";
import Password from "primevue/password";

export default Vue.extend({
  props: {
    ReturnPath: { type: String, default: null },
  },
  data() {
    return {
      email: "",
      password: "",
      twoFactorEnabled: false,
      authenticatorEnabled: false,
      authMethod: 0 as TwoFactorTypeEnum | 0,
      authMethods: [] as TwoFactorTypeEnum | [],
      token: "",
      view: 0 as screen | 0,
      affiliateIdentityProviders: { type: object, default: null },
      isPasswordShow: false as boolean,
    };
  },
  async created() {
    await this.init();
  },
  computed: {
    ...mapGetters({
      Theme: "getTheme",
      Content: "getContent",
      Role: "getRole",
      ShoppingCart: "getShoppingCart",
    }),
  },
  mounted() {
    this.setLoading(false);
    this.authMethod = TwoFactorTypeEnum.None;
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async onSubmitMFA(code: string, method: TwoFactorTypeEnum) {
      this.setLoading(true);
      const response = await store.dispatch("SignInMFA", { c: code, method: method, token: this.token });

      this.setLoading(false);
      if (response == null) {
        //token expired
        swal("Sign in failed!", "Invalid auth token", "error");
        this.reset();
        return;
      }
      if (!response) {
        swal("Sign in failed!", "Invalid code", "error");
        return;
      }
      await this.Signin();
    },
    async init() {
      this.affiliateIdentityProviders = await this.getAffiliateIdentityProvidersByAffiliateID();
    },
    async onOpenID(id: string, r: string) {
      this.setLoading(true);
      if (this.$route.query.r == null) {
        document.location.href = "/api/members/openid/?id=" + id;
      } else {
        document.location.href = "/api/members/openid/?id=" + id + "&r=" + this.$route.query.r;
      }
      this.setLoading(false);
    },
    async onSubmit(event: { preventDefault: () => void }) {
      event.preventDefault();
      this.setLoading(true);
      const response = await store.dispatch("SignIn", { u: this.email, p: this.password });

      this.setLoading(false);
      if (!response || !response.status) {
        swal("Sign in failed!", "Username or password is incorrect.", "error");
        return;
      }

      if (response.twoFactorEnabled) {
        this.twoFactorEnabled = true;
        this.token = response.token;
        this.authMethods = response?.authMethods;
        this.authMethod = this.authMethods?.includes(TwoFactorTypeEnum.App) ? TwoFactorTypeEnum.App : !this.authMethods?.includes(TwoFactorTypeEnum.SMS) ? TwoFactorTypeEnum.Email : TwoFactorTypeEnum.None;
        if (this.authMethods == null) {
          swal("Error", "An error occured while trying to retrieve your account details.");
          return;
        }

        this.view = screen.authenticator;
        return;
      }
      await this.Signin();
    },
    async Signin() {
      if (this.Role == "Patient") this.setupCart();

      this.setLoading(false);
      if (this.ReturnPath) {
        router.push(this.ReturnPath);
        return;
      }
      switch (this.Role) {
        case "Patient":
          router.push("/patientportal");
          break;
        case "PhotoVerify":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/photoverify");
          break;
        case "Physician":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/physician");
          break;
        case "Support":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/support");
          break;
        case "Pharmacy":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/pharmacy");
          break;
        case "Admin":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/admin");
          break;
        case "AffiliateAdmin":
          if (this.$gtm && this.$gtm.enabled()) {
            this.$gtm.enable(false);
          }
          router.push("/affiliateadmin");
          break;
        default:
          swal("Sign in failed!", "Something went wrong. Please refresh the screen.", "error");
          break;
      }
    },
    reset() {
      this.email = "";
      this.password = "";
      this.token = "";
      this.twoFactorEnabled = false;
      this.view = screen.Signin;
    },
    async setupCart() {
      if (this.ShoppingCart && this.ShoppingCart.items) await store.dispatch("SaveShoppingCartItem", this.ShoppingCart.items);
      const cart = await store.dispatch("GetShoppingCart");
      if (cart) {
        cart.visible = true;
        store.commit("setShoppingCart", cart);
      }
    },
    async getAffiliateIdentityProvidersByAffiliateID() {
      try {
        const response = await axios.get("/api/Home/GetAffiliateIdentityProvidersByAffiliateID");
        if (response && response.data && response.data.success) {
          return response.data.data;
        }
        return response.data ? response.data : null;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    togglePassword() {
      this.isPasswordShow = !this.isPasswordShow;
    },
  },
  components: {
    BContainer,
    BForm,
    BFormInput,
    BButton,
    BRow,
    BCol,
    TwoFactorComponent,
    Password,
  },
});
