
import router from "@/router";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { StyleValue } from "vue/types/jsx";
import store from "@/store";

export default {
  name: "LabMenu",
  props: {},
};
