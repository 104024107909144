// src/utils/htmlUtils.ts

// Define a type for the map object
type HtmlEntityMap = Record<string, string>;

export function sanitize(rawHTML: string): string {
  const map: HtmlEntityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;

  return rawHTML.replace(reg, (match) => map[match]); // No need for type assertion
}

export function unsanitize(sanitizedHTML: string): string {
  const map: HtmlEntityMap = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#x27;": "'",
    "&#x2F;": "/",
  };
  const reg = /&amp;|&lt;|&gt;|&quot;|&#x27;|&#x2F;/gi;

  return sanitizedHTML.replace(reg, (match) => map[match]); // No need for type assertion
}
