
import router from "@/router";
import { mapMutations } from "vuex";
import mixins from "vue-typed-mixins";
import Common from "@/mixins/Common";

export default mixins(Common).extend({
  props: {
    result: { type: String, default: null },
  },
  mounted() {
    // Remove header and footer
    document.getElementsByClassName("navbar")[0].setAttribute("style", "display: none");
    document.getElementsByClassName("footer-bg")[0].setAttribute("style", "display: none");
  },
  methods: {
    CloseModal() {
      window.top.postMessage({ messageType: "closeInstantModal" }, "*");
    },
  },
});
