import Vue from "vue";
import { ObjectSchema } from "yup";
import { Assign, ObjectShape } from "yup/lib/object";

export interface KeyValuePair {
  key: string;
  value: string;
}

export default Vue.extend({
  data() {
    return {
      schema: {} as ObjectSchema<Assign<ObjectShape, any>>,
      errors: [] as Array<KeyValuePair>,
    };
  },
  methods: {
    setupValidation(schema: ObjectSchema<Assign<ObjectShape, any>>) {
      this.schema = schema;
      (Object.keys(schema.fields) as (keyof typeof schema.fields)[]).forEach((key, index) => {
        this.$watch(key.toString(), (newVal: null | string) => {
          if (newVal === null || newVal === "") return;
          this.validate(key.toString());
        });
      });
    },
    getError(field: string) {
      return this.errors.find((x) => x.key == field)?.value;
    },
    validate(field: string) {
      const removeI = this.errors.findIndex((x) => x.key == field);
      if (removeI > -1) this.errors.splice(removeI, 1);
      this.schema.validateAt(field, this).catch((err: { message: any }) => {
        this.errors.push({ key: field, value: err.message });
      });
    },
  },
});
