//import "./registerServiceWorker";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "./css/custom.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import moment from "moment";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

import App from "./App.vue";
import NumberFilters from "./filters/NumberFilters";
import StringFilters from "./filters/StringFilters";
import router from "./router";
import store from "./store";
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import "img-comparison-slider";

// PrimeVue
// Resources
// https://forum.primefaces.org/viewtopic.php?t=68417
// https://stackoverflow.com/questions/72521936/primevue-data-table-styling-not-working-in-vue3
import PrimeVue from "primevue/config";
import "primevue/resources/themes/md-light-deeppurple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

Vue.use(PrimeVue, { unstyled: true });
Vue.config.productionTip = false;

Vue.config.ignoredElements = [/img-comparison-slider/];

Vue.use(StringFilters);
Vue.use(NumberFilters);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueReCaptcha, { siteKey: "6Ld9cBQiAAAAAJJ7pzjK2mXJ_BCCZL6gJvOHY9fO" });
Vue.use(VueEasytable);

router.beforeEach(async (to, from, next) => {
  try {
    const isAPIOnly = (store.state.Affiliate as any).isAPIOnly as boolean;

    if (isAPIOnly && to.name != "apionly") {
      next("/apionly");
      return;
    }

    next();
  } catch (error) {
    console.error("Error in navigation guard:", error);
    next(false); // Cancel the navigation if there's an error.
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Subscribe to store updates
store.subscribe((state) => {
  // Store the state object as a JSON string
  sessionStorage.setItem("s", JSON.stringify(store.state));
});

store.subscribeAction((action, state) => {
  if (action.type != "Refresh" && state.SignedIn && new Date() > moment(state.LastRefreshDate).add(15, "m").toDate()) {
    store.dispatch("Refresh");
  }
});
