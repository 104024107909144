
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import common from "../../mixins/Common";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import Vue, { PropType } from "vue";
import { mapMutations } from "vuex";
import PromoPricing from "@/mixins/PromoPricing";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { ProductRx } from "@/interfaces/ProductRx";

export default mixins(CustomValidation, common, PromoPricing).extend({
  name: "charge-confirmation",
  props: {
    otcPrice: { type: Number, default: 0 },
  },
  mounted() {
    this.importData(this.q.quest);
    this.setLoading(false);
  },
  watch: {
    q(newVal) {
      this.importData(newVal.quest);
    },
  },
  computed: {
    stepType(): string {
      return this.isPayment ? "payment" : "refill";
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
            break;
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as ProductRx[];
      }
      this.isExpeditedShipping = newVal.isExpeditedShipping;
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.outsidePromo !== null) {
        newVal.promoCode = this.outsidePromo;
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.PassedPromoCode !== null) {
        newVal.promoCode = this.PassedPromoCode;
      }
      if (this.promoCode != newVal.promoCode) {
        this.promoCode = newVal.promoCode;
        if (this.promoCode && this.promoCode.length > 0)
          this.GetPromoByCode(this.promoCode, null, () => {
            this.promoCode = null;
          });
      }
      this.isSubscription = qProductRx ? qProductRx.isSubscription : this.isSubscription;
    },
    next_click() {
      this.$emit("saveandnavigate", "+");
    },
    prev_click() {
      this.$emit("saveandnavigate", "-");
    },
  },
  components: { QuestWrapperComponent },
});
