
import { Answer } from "@/interfaces/Answer";
import { Question } from "@/interfaces/Question";
import axios from "axios";
import Vue from "vue";
import debounce from "../../node_modules/just-debounce";
import SelectItemVueComponentVue from "./SelectItemVueComponent.vue";
import vSelect from "vue-select";
import api from "@/webapi";

export default Vue.extend({
  name: "question-multiple-free-text",
  props: {
    value: Object,
    showerror: Boolean,
    placeholder: String,
    searchMethod: String,
    readonly: {
      default: false,
      type: Boolean,
    },
    userView: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      question: null as Question | null,
      IDCounter: null as number | null,
      initialLoad: true,
      options: [],
      selectedOption: null as string | null,
      selectedAnswersProc: false,
      timeout: 0,
    };
  },
  created() {
    this.importData(this.value);
  },
  computed: {
    selectedAnswersFiltered(): Array<Answer> | null {
      var result = [];
      if (this.question === null) return null;
      var selectedAnswers = this.question.selectedAnswers;
      for (var i = 0; i < selectedAnswers.length; i++) {
        if (selectedAnswers[i].answerID !== 999) {
          selectedAnswers[i].answerText = selectedAnswers[i].answerText != null ? selectedAnswers[i].answerText.toUpperCase() : selectedAnswers[i].answerText;
          result.push(selectedAnswers[i]);
        }
      }
      return result;
    },
    useSearch() {
      if (this.searchMethod && this.searchMethod.length > 0) return true;
      else return false;
    },
    handleReadonlyExcl() {
      if (this.question === null) return;
      if (!this.readonly) return false;
      if (!this.question.selectedAnswers) return false;
      if (this.userView) return false;
      for (var i = 0; i < this.question.selectedAnswers.length; i++) {
        if (this.question.selectedAnswers[i].deny) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    value(newVal) {
      this.initialLoad = true;
      this.importData(newVal);
    },
    selectedOption(val, oVal) {
      if (this.question === null) return;
      if (!this.readonly && val && val.trim().length > 0) {
        this.question.selectedAnswers.push(this.makeAnswer(val));
        this.selectedOption = "";
      }
    },
    "question.selectedAnswers": {
      handler(val) {
        if (this.selectedAnswersProc) {
          this.selectedAnswersProc = false;
          return;
        }

        var remove = null;
        for (var i = 0; i < val.length; i++) {
          if (val[i].answerText !== null && val[i].answerText.length === 0) {
            remove = i;
          }
        }
        if (remove !== null) {
          this.selectedAnswersProc = true;
          val.splice(remove, 1);
          remove = null;
        }
        // Add a new blank line after an entry is completed
        if (!this.useSearch && val.length && val[val.length - 1].answerText !== null && val[val.length - 1].answerText.length) {
          this.selectedAnswersProc = true;
          this.addAnswer(this.initialLoad);
          this.initialLoad = false;
        }
        // Add a new blank line if all are deleted
        if (!this.useSearch && val.length == 0) {
          this.selectedAnswersProc = true;
          this.addAnswer(true);
        }

        if ((this.useSearch && val.length > 1) || val.length > 2) {
          for (var j = 0; j < val.length; j++) {
            if (val[j].answerID === 999) {
              remove = j;
              break;
            }
          }
          if (remove !== null) {
            this.selectedAnswersProc = true;
            val.splice(remove, 1);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    importData(newVal: Question) {
      var selectedAnswers = newVal.selectedAnswers;
      if (newVal.exemptAnswer != null && selectedAnswers != null && selectedAnswers.length == 1 && selectedAnswers[0].answerText == newVal.exemptAnswer.answerText) {
        selectedAnswers[0] = newVal.exemptAnswer;
      }
      this.question = newVal;
      if (!this.question.selectedAnswers || this.question.selectedAnswers.length === 0) {
        this.question.selectedAnswers = [];
        if (!this.useSearch) {
          this.addAnswer(true);
        }
      }
    },
    addAnswer(skipFocus: boolean) {
      if (this.question === null) return;
      if (this.readonly) return;
      var a = this.makeAnswer("");
      this.selectedAnswersProc = true;
      this.question.selectedAnswers.push(a);
      if (!skipFocus)
        window.setTimeout(() => {
          if (this.question === null) return;
          const ipt = document.getElementById(this.question.questionID + "_" + a.answerID);
          if (ipt != null) (ipt as HTMLElement).focus();
        }, 100);
    },
    getID(): number {
      if (this.IDCounter === null) {
        this.IDCounter = this.question && this.question.selectedAnswers && this.question.selectedAnswers.length ? this.question.selectedAnswers[this.question.selectedAnswers.length - 1].answerID : 0;
      }
      this.IDCounter++;
      return this.IDCounter;
    },
    IsSelected(val: Answer) {
      if (this.question === null) return;
      if (!this.question.selectedAnswers) return false;
      for (var i = 0; i < this.question.selectedAnswers.length; i++) {
        if (this.question.selectedAnswers[i].answerID === val.answerID) {
          return true;
        }
      }
      return false;
    },
    makeAnswer(answerText: string): Answer {
      return { answerText: answerText != null ? answerText.toUpperCase() : answerText, followUpQuestionID: 0, order: 0, answerID: this.getID() };
    },
    onSearch(searchTerm: string) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        const data = await api.post(`/api/questions/${this.searchMethod}?searchTerm=${searchTerm}`);
        if (data) {
          if (!data) {
            return;
          }
          this.options = data.data;
        } else {
          console.log("data was null");
        }
      }, 250);
    },
    onRemoveAnswer(id: number) {
      if (this.question === null) return;
      if (this.readonly) return;
      if (this.question.selectedAnswers.length > 1 || (this.question.selectedAnswers.length === 1 && this.useSearch)) this.question.selectedAnswers.splice(id, 1);
    },
  },
  components: { SelectItemVueComponentVue, vSelect },
});
