<template>
  <div>
    <div v-if="!useDefaultDisplay" v-html="notFoundDisplay"></div>
    <div v-else class="notFound__Container">
      <div class="notFound__ContainerContent" v-html="notFoundDisplay"></div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      redirectSignedInInterval: 8000, // default is 8 seconds
      redirectNotSignedInInterval: 8000, // default is 8 seconds
      reDirectToHome: 0, // this is the timeOut
      reDirectedByRole: 0, // this is the timeOut
      autoRedirect: true,
      useDefaultDisplay: true,
      notFoundDisplay: `<p class="mb-4 lead">Oops, there isn't anything here.`,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      SignedIn: "getSignedIn",
      Role: "getRole",
    }),
  },
  beforeMount() {
    /*
    Content: {
        NotFound: {
            display: "",
            autoRedirect: false,
            notSignedInInterval: 0,
            signedInInterval: 0
        }
    }
    */
    if (this.Role !== "" && this.SignedIn) {
      this.notFoundDisplay += `You are being redirected to your dashboard.</p>`;
    } else {
      this.notFoundDisplay += `You are being redirected to our home page.</p>`;
    }

    if (this.Content && this.Content.NotFound && this.Content.NotFound.display && this.Content.NotFound.display !== "") {
      this.useDefaultDisplay = false;
      this.notFoundDisplay = this.Content.NotFound;
    }

    if (this.Content && this.Content.NotFound && !this.Content.NotFound.autoRedirect) {
      this.autoRedirect = false;
    } else if (this.Content && this.Content.NotFound && this.Content.NotFound.redirectInterval && this.Content.NotFound.notSignedInInterval && this.Content.NotFound.signedInInterval) {
      this.redirectNotSignedInInterval = this.Content.NotFound.notSignedInInterval;
      this.redirectSignedInInterval = this.Content.NotFound.signedInInterval;
    }
  },
  mounted() {
    if (this.autoRedirect) {
      if ((this.Role !== "") & this.SignedIn) {
        this.reDirectedByRole = setTimeout(() => {
          if (this.Role.toLowerCase() === "patient") {
            router.push("/patientportal");
          } else {
            router.push(`/${this.Role.toLowerCase()}`);
          }
        }, this.redirectSignedInInterval);
      } else {
        this.reDirectToHome = setTimeout(() => {
          router.push("/");
        }, this.redirectNotSignedInInterval);
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.reDirectedByRole);
    clearTimeout(this.reDirectToHome);
  },
};
</script>
