
import Vue, { set } from "vue";
import mixins from "vue-typed-mixins";
import { mapGetters, mapMutations } from "vuex";
import { number, object, string, boolean } from "yup";
import router from "@/router";
import axios from "axios";
import store from "@/store";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
export default Vue.extend({
  data() {
    return {
      code: null as string | null,
      validated: false as boolean | false,
      error: "" as string | "",
      loading: true as boolean | true,
    };
  },
  async mounted() {
    this.code = router.currentRoute.query.code?.toString();
    if (this.code == "") {
      this.error = "Invalid confirmation code or user";
      this.loading = false;
      return;
    }
    this.setLoading(true);
    var rsp = await store.dispatch("UserEmailConfirmation", { code: this.code });
    this.setLoading(false);
    if (rsp?.data) {
      this.validated = true;
    } else {
      this.error = rsp.errors[0];
    }
    this.loading = false;
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
  },
});
