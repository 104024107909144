
import Vue from "vue";
import ShoppingCartItemComponent from "./ShoppingCartItemComponent.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import store from "../store/index";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import { ProductPrice } from "@/interfaces/ProductPrice";
import router from "../router";

export default Vue.extend({
  data() {
    return {
      showCart: true as boolean,
    };
  },
  computed: {
    ...mapGetters({
      ShoppingCart: "getShoppingCart",
      SignedIn: "getSignedIn",
      getOTCProducts: "getOTCProducts",
      FirstName: "getFirstName",
      ShowCart: "getShowCart",
    }),
    OTCProducts(): OTCProductViewModel[] {
      return this.getOTCProducts;
    },
    totalCost(): number {
      let sum = 0;
      const c = parseFloat(this.ShoppingCart?.items?.reduce((sum: number, item: ShoppingCartItem) => sum + item.price, 0)) + parseFloat(this.shippingCost);
      return !c ? 0 : c;
    },
    shippingCost(): number {
      const c = this.ShoppingCart && this.ShoppingCart.shippingCost ? parseFloat(this.ShoppingCart?.shippingCost) : 5;
      return c.toFixed(2);
    },
  },
  mounted() {
    if (router.currentRoute.name == "cart") {
      this.ShoppingCart.visible = false;
      return;
    }
  },
  methods: {
    ...mapMutations({
      setShowCart: "setShowCart",
    }),
    checkout() {
      this.setShowCart(false);
      if (router.currentRoute.fullPath != "/checkout/checkout") router.push("/checkout");
    },
    async itemRemoved(item: ShoppingCartItem) {
      if (!item || !this.ShoppingCart || !this.ShoppingCart.items) {
        return;
      }

      const index = this.ShoppingCart.items.findIndex((i: ShoppingCartItem) => i.sid == item.sid);
      if (index < 0) {
        console.log("invalid cart item");
        return;
      }
      const cart = this.ShoppingCart;
      item.active = false;

      if (this.SignedIn) {
        const rsp = await store.dispatch("SaveShoppingCartItem", [item]);
        if (rsp) {
          cart.items.splice(index, 1);
          store.commit("setShoppingCart", cart);
        }
      } else {
        cart.items.splice(index, 1);
        store.commit("setShoppingCart", cart);
      }

      if (cart.items.length == 0) {
        cart.shown = false;
        cart.visible = false;
      }
    },
    async itemQtyChanged(item: ShoppingCartItem, qty: number) {
      if (!item || !this.ShoppingCart || !this.ShoppingCart.items) {
        return;
      }

      const index = this.ShoppingCart.items.findIndex((i: ShoppingCartItem) => i.sid == item.sid);
      if (index < 0) {
        console.log("invalid cart item");
        return;
      }
      const pqty = this.ShoppingCart.items[index].qty;
      const pi = this.OTCProducts.findIndex((i: OTCProductViewModel) => i.id == item.productID && i.productType == item.productTypeID);
      if (pi < 0) {
        console.log("invalid product");
        return;
      }
      const product = this.OTCProducts[pi];
      console.log(product);
      const price = product.prices.findIndex((i: ProductPrice) => qty >= i.minQty && qty <= i.maxQty);
      console.log(price);

      if (price < 0) {
        item.qty = pqty;
        console.log("invalid price");
        return;
      }
      const basePrice: number = qty * product.prices[price].pricePerUnit;
      const p = basePrice * product.prices[price].markupPercentage;

      item.qty = qty;
      item.markup = product.prices[price].markup;
      item.pricePerUnit = product.prices[price].pricePerUnit;
      item.price = p;
      this.ShoppingCart.items[index].qty = qty;
      this.ShoppingCart.items[index].markup = product.prices[price].markup;
      this.ShoppingCart.items[index].pricePerUnit = product.prices[price].pricePerUnit;
      this.ShoppingCart.items[index].price = p;

      if (this.SignedIn) {
        const rsp = await store.dispatch("SaveShoppingCartItem", [item]);
        if (rsp) {
          console.log(rsp.cart);
          rsp.cart.visible = true;
          rsp.cart.shown = true;
          store.commit("setShoppingCart", rsp.cart);
        }
        return;
      } else {
        store.commit("setShoppingCart", this.ShoppingCart);
      }
    },
    toggleCart() {
      this.setShowCart(!this.ShowCart);
    },
    openCart() {
      this.setShowCart(true);
    },
    closeCart() {
      this.setShowCart(false);
    },
  },
  components: {
    ShoppingCartItemComponent,
  },
});
