
import { mapGetters, mapMutations } from "vuex";
import WeightTracker from "@/components/WeightTrackerComponent.vue";
import store from "@/store";
import { VitalTypeIDEnum } from "@/interfaces/VitalTypeIDEnum";
import MedicineTracker from "@/components/MedicineTrackerComponent.vue";

export default {
  name: "patient-widgets",
  props: {
    usersName: {
      type: String,
      required: true,
    },
    displayWeightTracker: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data(props) {
    return {
      doShowFull: 0,
      notShowFull: 0,
      reRenderTrigger: true,
      displayData: props.displayWeightTracker,
      hasData: false,
      goalWeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      FullPatientWidgetData: "getFullPatientWidgetData",
    }),
  },
  mounted() {
    if (this.displayData && this.FullPatientWidgetData && this.FullPatientWidgetData.wtData.weightTrackerObject && this.FullPatientWidgetData.wtData && this.FullPatientWidgetData.wtData.weightLogEntries && this.FullPatientWidgetData.wtData.weightLogEntries.length > 0) {
      const heightIndex = this.FullPatientWidgetData.wtData.weightLogEntries.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Height);
      const bmiIndex = this.FullPatientWidgetData.wtData.weightLogEntries.filter((element) => element.vitalTypeID === VitalTypeIDEnum.BMI).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
      const currentWeightIndex = this.FullPatientWidgetData.wtData.weightLogEntries.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Weight).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
      const startingWeightIndex = this.FullPatientWidgetData.wtData.weightLogEntries.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Weight).sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());

      if (heightIndex.length > 0 && bmiIndex.length > 0 && currentWeightIndex.length > 0 && startingWeightIndex.length > 0) {
        this.hasData = true;
        const wtO = JSON.parse(this.FullPatientWidgetData.wtData.weightTrackerObject.value);
        if (wtO.goalWeight) {
          this.goalWeight = wtO.goalWeight;
        }
      }
    }
  },
  methods: {
    ShowWeightTrackerModal() {
      this.$bvModal.show("modal-weight");
    },
    ShowMedTrackerModal() {
      this.$bvModal.show("modal-med");
    },
    async rerender() {
      await store.dispatch("GetFullPatientWidgetData");
      const wtO = JSON.parse(this.FullPatientWidgetData.wtData.weightTrackerObject.value);
      if (wtO.goalWeight) {
        this.goalWeight = wtO.goalWeight;
      }
      this.reRenderTrigger = false;
      this.reRenderTrigger = true;
      this.notShowFull++;
      this.doShowFull++;
    },
  },
  components: {
    WeightTracker,
    MedicineTracker,
  },
};
