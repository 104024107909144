
import router from "@/router";
import Vue from "vue";
import { mapMutations } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";

export default Vue.extend({
  name: "ErrorView",
  props: {
    errors: Array,
    show: Boolean,
    navButtonText: {
      default: "Retry",
      type: String,
    },
    navPageId: {
      default: 0,
    },
    refillHeld: {
      default: false,
      type: Boolean,
    },
    hardStop: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      localNavButtonText: null as string | null,
    };
  },
  watch: {
    show() {
      this.setLoading(false);
    },
    navButtonText(newVal: string) {
      this.fixNavButtonText(newVal);
    },
  },
  mounted() {
    this.fixNavButtonText(this.navButtonText);
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    fixNavButtonText(newVal: string) {
      if (!newVal) this.localNavButtonText = "RETRY";
      else this.localNavButtonText = newVal;
    },
    reload() {
      if (this.$route.name?.indexOf("questionnaire") && this.$route.name?.indexOf("questionnaire") > -1) router.push("/questionnaire");
      if (this.$route.name?.indexOf("refill") && this.$route.name?.indexOf("refill") > -1) router.push("/refill");
      if (this.$route.name?.indexOf("payment") && this.$route.name?.indexOf("payment") > -1) router.push("/payment");
      if (this.$route.name?.indexOf("photos") && this.$route.name?.indexOf("photos") > -1) router.push("/photos");
      if (this.$route.name?.indexOf("modify") && this.$route.name?.indexOf("modify") > -1) router.push("/modify");
      location.reload();
    },
    next_click() {
      switch (this.navPageId) {
        case -1:
          this.reload();
          break;
        case 0:
          this.reload();
          break;
        case 99:
          router.push("/patientportal");
          break;
        default:
          this.$emit("navigateto", this.navPageId);
      }
    },
  },
  components: { QuestWrapperComponent },
});
