
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { mapGetters } from "vuex";
import { object, number } from "yup";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { ProductRxGroup } from "../../interfaces/ProductRxGroup";
import { AffiliateProductRx } from "../../interfaces/AffiliateProductRx";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";

export default mixins(CustomValidation).extend({
  mixins: [CustomValidation],
  name: "choose-medication",
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
  },
  data() {
    return {
      schema: object().shape({
        selectedProductRxGroup: object().shape({
          id: number().typeError("Product is required.").required(),
        }),
      }),
      selectedProductRxGroup: {} as any,
      selectedProduct: null as any,
      userSelectedProduct: false as any,
    };
  },
  created() {
    this.setupValidation(this.schema);
    this.importData(this.q.quest);
  },
  watch: {
    q(newVal: QuestViewModel) {
      this.importData(newVal.quest);
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
    }),
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    ProductRxGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    nextText(): string {
      if (this.isRefill) return "Order Refill";
      else return "Continue";
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : "new";
    },
  },
  methods: {
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.ProductRxGroups.length; i++) {
        if (qProductRx && this.ProductRxGroups[i].id === qProductRx.productRxGroupID) {
          this.selectedProductRxGroup = this.ProductRxGroups[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (qProductRx && this.products[i].id === qProductRx.productRxID) {
          this.selectedProduct = this.products[i];
          break;
        }
      }
    },
    lowestPrice(productRxGroupID: number): number {
      var prods = this.q.products.filter((x) => x.productRxGroupID == productRxGroupID);
      var lowestProd = prods.reduce((acc, curr) => (curr.programPrice < acc.programPrice ? curr : acc), prods[0] || undefined);
      var currentQTP = this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID);
      return lowestProd.programPrice + (currentQTP && currentQTP.length && currentQTP[0].price ? currentQTP[0].price : 0);
    },
    lowestProductRx(productRxGroupID: number): AffiliateProductRx {
      var prods = this.q.products.filter((x) => x.productRxGroupID == productRxGroupID);
      var lowestProd = prods.reduce((acc, curr) => (curr.programPrice < acc.programPrice ? curr : acc), prods[0] || undefined);
      return lowestProd;
    },
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    pgStyle(pg: ProductRxGroup) {
      if (this.selectedProductRxGroup == pg) return "border: 1px solid " + "#000000" + " !important; min-width:369px; max-width:475px";
      else return "min-width:369px; max-width:475px";
    },
    radioWrapperClass(pg: ProductRxGroup) {
      return pg.color == "#F4B64E" ? "rdo-wrapper rdo-wrapper-yellow" : "rdo-wrapper";
    },
    setProductRxGroup(pg: ProductRxGroup) {
      this.selectedProductRxGroup = pg;
      this.selectedProduct = null;
    },
    getUpdatedData() {
      var q = Object.assign({}, this.q.quest);
      var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
      qProductRx.productRxGroupID = this.selectedProductRxGroup ? this.selectedProductRxGroup.id : null;
      qProductRx.productRxID = this.selectedProduct ? this.selectedProduct.id : null;
      q.qProductRxs = [qProductRx];
      return q;
    },
    next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          this.$emit("saveandnavigate", "+", this.getUpdatedData());
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getUpdatedData());
    },
  },
  components: { QuestWrapperComponent },
});
