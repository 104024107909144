import { VueConstructor } from "vue/types/umd";

export default {
  install(Vue: VueConstructor) {
    Vue.filter("toOrdinal", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      const j = value % 10,
        k = value % 100;
      if (j === 1 && k !== 11) {
        return value + "st";
      }
      if (j === 2 && k !== 12) {
        return value + "nd";
      }
      if (j === 3 && k !== 13) {
        return value + "rd";
      }
      return value + "th";
    });

    Vue.filter("toCurrency", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });

    Vue.filter("toCurrencyFree", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      if (value === 0) return "Included";
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });

    Vue.filter("toCurrencyNoChangeFree", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      if (value === 0) return "Included";
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      if (Math.round(value) - value == 0) {
        formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }
      return formatter.format(value);
    });

    Vue.filter("daysToWeeks", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      return Math.floor(value / 7);
    });

    Vue.filter("toCurrencyNoChangeOperator", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      if (Math.round(value) - value == 0) {
        formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }
      if (value < 1 && value > 0) return formatter.format(value).replace("$0.", "") + "¢";
      return (value >= 0 ? "+ " : value < 0 ? "- " : "") + formatter.format(value).replace("-", "");
    });

    Vue.filter("toCurrencyNoChange", function (value: string | number) {
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      if (Math.round(value) - value == 0) {
        formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }
      if (value < 1 && value > 0) return formatter.format(value).replace("$0.", "") + "¢";
      return formatter.format(value);
    });

    Vue.filter("toWholeNum", function (value: string | number) {
      if (!value) {
        return value;
      }
      if (typeof value !== "number") {
        const parsed = parseInt(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      return Math.round(value);
    });

    Vue.filter("toAbs", function (value: string | number) {
      if (!value) {
        return value;
      }
      if (typeof value !== "number") {
        const parsed = parseFloat(value);
        if (isNaN(parsed)) return value;
        else value = parsed;
      }
      return Math.abs(value);
    });
  },
};
