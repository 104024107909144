
import { Answer } from "@/interfaces/Answer";
import { Question } from "@/interfaces/Question";
import SelectItemVueComponentVue from "./SelectItemVueComponent.vue";
import Vue from "vue";

export default Vue.extend({
  name: "question-single-choice",
  props: {
    value: Object,
    multipleChoice: Boolean,
    showerror: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
    userView: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      question: {} as Question,
      firstLoad: true,
    };
  },
  created() {
    this.importData(this.value);
  },
  computed: {
    handleReadonlyExcl() {
      if (!this.readonly) return false;
      if (!this.question.selectedAnswers) return false;
      if (this.userView) return false;
      for (let i = 0; i < this.question.selectedAnswers.length; i++) {
        if (this.question.selectedAnswers[i].deny) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    value(newVal) {
      this.importData(newVal);
      this.firstLoad = true;
    },
    "value.selectedAnswers"(val: Array<Answer>, oVal: Array<Answer>) {
      for (let i = 0; i < val.length; i++) {
        if (val[i].followUpQuestionID > 0) {
          this.$emit("showquestion", val[i].followUpQuestionID);
        }
      }
      if (!oVal) {
        return;
      }
      for (let j = 0; j < oVal.length; j++) {
        oVal[j].fired = false;
        if (oVal[j].followUpQuestionID > 0) {
          let found = false;
          for (let k = 0; k < val.length; k++) {
            if (val[k].followUpQuestionID === oVal[j].followUpQuestionID) {
              found = true;
            }
          }
          if (!found) {
            this.$emit("hidequestion", oVal[j].followUpQuestionID);
          }
        }
      }
      let removeID = -1;
      let diff = val.filter((x) => oVal.indexOf(x) == -1); //$(val).not(oVal).get();
      if (this.question.exemptAnswer && diff.length > 0 && diff[0].answerID !== this.question.exemptAnswer.answerID) {
        for (let l = 0; l < this.question.selectedAnswers.length; l++) {
          if (this.question.selectedAnswers[l].answerID === this.question.exemptAnswer.answerID) {
            removeID = l;
          }
        }
      }
      if (removeID > -1) {
        this.question.selectedAnswers.splice(removeID, 1);
      }
      this.firstLoad = false;
    },
  },
  methods: {
    importData(newVal: Question) {
      this.question = newVal;
      this.question.selectedAnswers = this.question.selectedAnswers ? this.question.selectedAnswers : [];
    },
    IsSelected(val: Answer) {
      if (!this.question.selectedAnswers) return false;
      for (let i = 0; i < this.question.selectedAnswers.length; i++) {
        if (this.question.selectedAnswers[i].answerID === val.answerID) {
          return true;
        }
      }
      return false;
    },
  },
  components: { SelectItemVueComponentVue },
});
