
import Vue, { PropType, readonly } from "vue";
import ChoiceVueComponent from "@/components/ChoiceVueComponent.vue";
import { Question } from "@/interfaces/Question";
import { object } from "yup";
import { Answer } from "@/interfaces/Answer";
export default Vue.extend({
  name: "question-selection-choice",
  props: {
    value: Object,
    showerror: Boolean,
    multipleChoice: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
    userView: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      question: {} as Question,
      selected: false,
      answer: {} as Answer,
    };
  },
  created() {
    this.importData(this.value);
  },
  mounted() {
    this.importData(this.value);
  },
  computed: {
    handleReadonlyExcl() {
      if (!this.readonly) return false;
      if (!this.question.selectedAnswers) return false;
      if (this.userView) return false;
      for (let i = 0; i < this.question.selectedAnswers.length; i++) {
        if (this.question.selectedAnswers[i].deny) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    value(nv) {
      this.importData(nv);
    },
  },
  methods: {
    importData(q: Question) {
      this.question = q;
    },
    onChange(answer: Answer, selected: boolean) {
      if (selected && !this.question.selectedAnswers.some((a) => a.answerID == answer.answerID)) {
        if (!this.multipleChoice) {
          this.question.selectedAnswers = [];
        }
        this.question.selectedAnswers.push(answer);
      }
      if (!selected) {
        this.question.selectedAnswers = this.question.selectedAnswers.filter((i) => i.answerID != answer.answerID);
      }
    },
    onClick(e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.readonly) {
        this.selected = !this.selected;
        this.$emit("selectedChanged", this.answer, this.selected);
      }
    },
  },
  components: { ChoiceVueComponent },
});
