
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import swal from "sweetalert";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  props: {
    vm: { type: String, default: null },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      Theme: "getTheme",
      Content: "getContent",
      Role: "getRole",
      ShoppingCart: "getShoppingCart",
    }),
  },
  mounted() {
    this.setLoading(false);
    this.onOpenID();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async onOpenID() {
      this.setLoading(true);
      if (this.vm) {
        let vm = JSON.parse(this.vm);
        await store.dispatch("SignInOpenID", vm);
      } else {
        router.push("/signin");
        this.setLoading(false);
        return;
      }
      switch (this.Role) {
        case "Patient":
          this.setupCart();
          if (this.$route.query.r != null) {
            router.push(this.$route.query.r.toString());
          } else {
            router.push("/patientportal");
          }
          break;
        case "PhotoVerify":
          if (this.$route.query.r != null) {
            router.push(this.$route.query.r.toString());
          } else {
            router.push("/photoverify");
          }
          break;
        case "Physician":
          if (this.$route.query.r != null) {
            router.push(this.$route.query.r.toString());
          } else {
            router.push("/physician");
          }
          break;
        case "Support":
          if (this.$route.query.r != null) {
            router.push(this.$route.query.r.toString());
          } else {
            router.push("/support");
          }
          break;
        case "Pharmacy":
          if (this.$route.query.r != null) {
            router.push(this.$route.query.r.toString());
          } else {
            router.push("/pharmacy");
          }
          break;

        default:
          swal("Sign in failed!", "Something went wrong. Please refresh the screen.", "error");
          break;
      }
      this.setLoading(false);
    },
    async setupCart() {
      if (this.ShoppingCart && this.ShoppingCart.items) await store.dispatch("SaveShoppingCartItem", this.ShoppingCart.items);
      const cart = await store.dispatch("GetShoppingCart");
      if (cart) {
        cart.visible = true;
        store.commit("setShoppingCart", cart);
      }
    },
  },
});
