
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { mapGetters } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import QuestionSingleChoiceVueComponentVue from "@/components/QuestionSingleChoiceVueComponent.vue";
import QuestionFreeTextVueComponentVue from "@/components/QuestionFreeTextVueComponent.vue";
import QuestionMultipleFreeTextVueComponentVue from "@/components/QuestionMultipleFreeTextVueComponent.vue";
import QuestionImageVueComponentVue from "@/components/QuestionImageVueComponent.vue";
import { Question } from "@/interfaces/Question";
import { Answer } from "@/interfaces/Answer";
import { Questions } from "@/interfaces/Questions";
import { QuestionTypeEnum } from "@/interfaces/QuestionTypeEnum";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { RefillViewModel } from "@/interfaces/RefillViewModel";
import QuestionMultipleChoiceVueComponent from "@/components/QuestionMultipleChoiceVueComponent.vue";
import QuestionCalculatorComponent from "@/components/QuestionCalculatorComponent.vue";
import PromoPricing from "@/mixins/PromoPricing";
import { ProductRx } from "@/interfaces/ProductRx";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import QuestionInformationVueComponent from "@/components/QuestionInformationVueComponent.vue";

export default mixins(CustomValidation, PromoPricing).extend({
  name: "QuestionsView",
  mixins: [CustomValidation],
  props: {
    questsPerPage: {
      default: 3,
      type: Number,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    showAll: {
      default: false,
      type: Boolean,
    },
    isRefill: {
      default: false,
      type: Boolean,
    },
    isModify: {
      default: false,
      type: Boolean,
    },
    userView: {
      default: false,
      type: Boolean,
    },
    visitRequired: { default: false, type: Boolean },
    hardStop: { default: false, type: Boolean },
    disableProductSelection: { default: false, type: Boolean },
  },
  data() {
    return {
      questions: [] as Array<Question>,
      currentPage: 0 as number,
      questionTypeEnum: QuestionTypeEnum,
      questsPerPageLocal: this.questsPerPage as number,
    };
  },
  async mounted() {
    if (!this.questionsObj) return;
    if (this.q && this.q.quest && this.q.quest.affiliateID == 6) this.questsPerPageLocal = 99;
    else this.questsPerPageLocal = 3;
    this.importQuestData(this.q.quest);
    this.importData(this.questionsObj);
    this.showCurrentPage();
    //console.log("this.currentPage: " + this.currentPage);
  },
  watch: {
    questionsObj(newVal) {
      this.importData(newVal);
    },
    q(newVal: QuestViewModel) {
      this.importQuestData(newVal.quest);
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    questionnaireTypeID(): number {
      //console.log("questionnaireTypeID");
      //console.log(this.q);
      return this.q && this.q.quest ? this.q.quest.questionnaireTypeID : this.q.rx ? this.q.rx.questionnaireTypeID : 0;
    },
    isRx(): boolean {
      return this.q && this.q.rx ? true : false;
    },
    questionsObj(): Questions | null {
      return this.isRefill || this.isModify ? (this.q as RefillViewModel).refillQuestionsObj : this.q && this.q.quest && this.q.quest.questionsObj ? this.q.quest.questionsObj : this.q && this.q.questionsObj ? this.q.questionsObj : null;
    },
    showExcludedOnly(): boolean {
      return this.readonly && !this.showAll;
    },
    numberOfPages(): number {
      if (this.readonly) return 1;
      if (!this.baseQuests || this.baseQuests.length === 0) {
        return 0;
      }
      return Math.ceil(this.baseQuests.length / this.questsPerPageLocal);
    },
    baseQuests(): Array<Question> {
      var result = [] as Array<Question>;
      for (var i = 0; i < this.questions.length; i++) {
        if (this.questions[i].isFollowUpQuestion === false) {
          result.push(this.questions[i]);
        }
      }
      return result;
    },
    stepType(): string {
      return this.readonly ? "none" : this.isRefill ? "refill" : this.isModify ? "modify" : "new";
    },
  },
  methods: {
    importQuestData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
            break;
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as ProductRx[];
      }
      //this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isExpeditedShipping = newVal.isExpeditedShipping;
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.outsidePromo !== null) {
        newVal.promoCode = this.outsidePromo;
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.PassedPromoCode !== null) {
        newVal.promoCode = this.PassedPromoCode;
      }
      if (this.promoCode != newVal.promoCode) {
        this.promoCode = newVal.promoCode;
        if (this.promoCode && this.promoCode.length > 0)
          this.GetPromoByCode(this.promoCode, null, () => {
            this.promoCode = null;
          });
      }
      this.isSubscription = qProductRx ? qProductRx.isSubscription : this.isSubscription;
    },
    importData(newVal: Questions) {
      if (!newVal) return;
      this.questions = newVal.items;
      //console.log(newVal);
      //console.log("this.currentPage: " + this.currentPage);
      //console.log("newVal.LastQuestionScreen: " + newVal.lastQuestionScreen);
      this.currentPage = newVal.lastQuestionScreen;
      this.showCurrentPage();
    },
    getCurrentQuestions() {
      if (!this.baseQuests || this.baseQuests.length === 0) {
        return [];
      }
      if (this.readonly) return this.baseQuests;
      var i = this.currentPage * this.questsPerPageLocal;
      return this.baseQuests.slice(i, i + this.questsPerPageLocal);
    },
    getUpdatedData() {
      var q = Object.assign({}, this.questionsObj);
      q.items = this.questions;
      q.lastQuestionScreen = this.currentPage;
      return q;
    },
    showQuestion(qID: number): Question | null {
      for (var i = 0; i < this.questions.length; i++) {
        if (this.questions[i].questionID === qID) {
          this.questions[i].hide = false;
          return this.questions[i];
        }
      }
      return null;
    },
    handleReadonlyShow(selAns: Array<Answer>) {
      if (!this.readonly || !this.showExcludedOnly) return true;
      if (!selAns) return false;
      for (var i = 0; i < selAns.length; i++) {
        if (selAns[i].deny) {
          return true;
        }
      }
      return false;
    },
    hideQuestion(qID: number) {
      for (var i = 0; i < this.questions.length; i++) {
        if (this.questions[i].questionID === qID) {
          this.questions[i].hide = true;
          if (this.questions[i].selectedAnswers && this.questions[i].selectedAnswers.length) {
            for (var j = 0; j < this.questions[i].selectedAnswers.length; j++) {
              this.hideQuestion(this.questions[i].selectedAnswers[j].followUpQuestionID);
            }
          }
          this.questions[i].selectedAnswers = [];
          return;
        }
      }
    },
    showCurrentPage() {
      var currentQuestions = this.getCurrentQuestions();
      for (var i = 0; i < this.questions.length; i++) {
        this.questions[i].hide = true;
      }
      for (var j = 0; j < currentQuestions.length; j++) {
        var cq = currentQuestions[j];
        this.showAndProcessQuestion(cq);
      }
    },
    showAndProcessQuestion(cq: Question | null) {
      if (cq == null) return;
      cq.hide = false;
      if (cq.selectedAnswers && cq.selectedAnswers.length > 0) {
        for (var k = 0; k < cq.selectedAnswers.length; k++) {
          var sa = cq.selectedAnswers[k];
          if (sa.followUpQuestionID > 0) {
            var fq = this.showQuestion(sa.followUpQuestionID);
            this.showAndProcessQuestion(fq);
          }
        }
      }
    },
    next_click() {
      var complete = true;
      var focusI = -1;
      var forceScreenChange = false;
      var hardStop = false;
      //console.log("this.currentPage: " + this.currentPage);

      // Mobile required field focus fix
      (document.getElementsByClassName("btn-next")[0] as HTMLElement).focus();

      window.setTimeout(() => {
        for (var i = 0; i < this.questions.length; i++) {
          if (!this.questions[i].hide && (!this.questions[i].selectedAnswers || this.questions[i].selectedAnswers.length === 0 || !this.questions[i].selectedAnswers[0].answerText || this.questions[i].selectedAnswers[0].answerText === "")) {
            if (this.questions[i].questionType === this.questionTypeEnum.Information) {
              complete = true;
              this.questions[i].showError = false;
            } else {
              complete = false;
              this.questions[i].showError = true;
              focusI = focusI === -1 ? i : focusI;
            }
          } else {
            this.questions[i].showError = false;
            if (this.questions[i].selectedAnswers) {
              for (var j = 0; j < this.questions[i].selectedAnswers.length; j++) {
                if (this.questions[i].selectedAnswers[j] && this.questions[i].selectedAnswers[j].hardStop) {
                  hardStop = true;
                  break;
                }
              }
            }
          }
        }
        if (complete) {
          //console.log("this.currentPage: " + this.currentPage);
          //console.log("(this.numberOfPages - 1): " + (this.numberOfPages - 1));
          if (this.currentPage < this.numberOfPages - 1) {
            this.currentPage += 1;
            this.showCurrentPage(); // do this here right now for testing purposes
          } else forceScreenChange = true;
          //console.log("forceScreenChange: " + forceScreenChange);
          this.$emit("saveandnavigate", "+", this.getUpdatedData(), forceScreenChange, hardStop);
        }
        if (focusI > -1)
          window.setTimeout(function () {
            /*$("html, body").animate(
              {
                scrollTop: jQuery("#" + this.questions[focusI].questionID).offset().top - 40,
              },
              1000
            );*/
          }, 100);
      }, 100);
    },
    prev_click() {
      let forceScreenChange = false;
      if (this.currentPage > 0) this.currentPage -= 1;
      else forceScreenChange = true;
      var skipShipping = false;
      if ((this.selectedProducts && this.selectedProducts.length > 0 && this.selectedProducts[0].isIntroductory) || !this.selectedProductSubscriptionAvailable || !this.isSubscription) skipShipping = true;
      this.$emit("saveandnavigate", "-", this.getUpdatedData(), forceScreenChange, false, skipShipping);
    },
    toggleShowExcludedOnly() {
      this.showExcludedOnly = !this.showExcludedOnly;
    },
  },
  components: { QuestionInformationVueComponent, QuestionImageVueComponentVue, QuestionMultipleFreeTextVueComponentVue, QuestionSingleChoiceVueComponentVue, QuestionFreeTextVueComponentVue, QuestWrapperComponent, QuestionMultipleChoiceVueComponent, QuestionCalculatorComponent },
});
