
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import moment from "moment";
import { mapMutations } from "vuex";
import swal from "sweetalert";
import { PatientMessageView } from "@/interfaces/PatientMessageView";
import { FolderTypeEnum } from "@/interfaces/FolderTypeEnum";
import { PatientMessagesPayloadViewModel } from "@/interfaces/PatientMessagesPayloadViewModel";
import store from "@/store";
import { PropType } from "vue";
import { BTabs, BTab, BCard, BCardText, BCardSubTitle, BFormSelect, BFormSelectOption, BIcon } from "bootstrap-vue";

export default mixins(CustomValidation, common).extend({
  name: "patient-messages",
  props: {
    payload: Object as PropType<PatientMessagesPayloadViewModel>,
    noNewMessage: { default: false, type: Boolean },
  },
  mixins: [common],
  data() {
    return {
      PatientMessages: [] as PatientMessageView[],
      CannedMessages: [] as string[],
      NewMessage: "",
      folderType: -99,
      showMessages: [] as number[],
      folderCount: [] as number[],
      folders: [] as number[],
      FolderTypeEnum: FolderTypeEnum,
    };
  },
  mounted() {
    this.reset();
    if (this.payload.isDoctor) this.getCannedMessages();
    if (this.payload.isSupport) this.folderType = 0;
    if (this.payload.isPharmacy) this.folderType = 999;
  },
  watch: {
    payload: {
      handler(newValue) {
        this.reset();
      },
      deep: true,
    },
  },
  computed: {
    currentFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType == this.folderType) result.push(v);
      });
      return result;
    },
    medicalFolderUnread(): number {
      var unread = this.medicalFolder.filter((obj) => {
        return obj.readDate === null;
      });
      return unread ? unread.length : 0;
    },
    medicalFolderCount(): number {
      return this.medicalFolder ? this.medicalFolder.length : 0;
    },
    medicalFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType != 0) result.push(v);
      });
      return result;
    },
    supportFolderUnread(): number {
      var unread = this.supportFolder.filter((obj) => {
        return obj.readDate === null;
      });
      return unread ? unread.length : 0;
    },
    supportFolderCount(): number {
      return this.supportFolder ? this.supportFolder.length : 0;
    },
    supportFolder(): PatientMessageView[] {
      var result = [] as PatientMessageView[];
      this.PatientMessages.forEach((v) => {
        if (v.folderType == 0) result.push(v);
      });
      return result;
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setQuestionnaire: "setQuestionnaire",
    }),
    newMessageClick() {
      if (this.payload.isSupport) {
        this.filter(0);
      } else {
        if (this.payload.isPharmacy) {
          this.filter(999);
        } else {
          this.filter(-1);
        }
      }
    },
    filter(i: number) {
      this.folderType = i;
    },
    async btnFWDToSupport_Click(patientMessageID: number, patientAffiliateID: number) {
      var postData = { patientMessageID: patientMessageID, patientAffiliateID: patientAffiliateID };
      this.setLoading(true);
      const result = await store.dispatch("FWDMSGToSupport", postData);
      this.setLoading(false);
      if (!result) {
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      swal({
        text: "",
        title: "Message sent!",
        icon: "success",
      }).then(() => {
        if (this.payload.onSendCallback) {
          this.payload.onSendCallback();
        }
      });
    },
    btnMedical_Click() {
      this.folderType = 0;
    },
    btnSupport_Click() {
      this.folderType = 1;
    },
    async reset() {
      this.NewMessage = "";
      await this.getPatientMessages();
    },
    async getPatientMessages() {
      var postData = { patientId: this.payload.patientId, patientAffiliateID: this.payload.patientAffiliateID, questionnaireTypeID: this.payload.questionnaireTypeID };
      this.setLoading(true);
      const result = await store.dispatch("GetPatientMessages", postData);
      if (!result) {
        this.setLoading(false);
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      this.PatientMessages = [];
      var array: PatientMessageView[] = result.patientMessages;
      var fc = Array.apply(null, Array(1000)).map(() => {
        return 0;
      });
      var fs = [] as number[];
      array.forEach((v) => {
        this.PatientMessages.push(v);
        fc[v.folderType] += 1;
        console.log(this.folderCount[v.folderType]);
        console.log(v.folderType);
      });
      fc.forEach((f, i) => {
        if (f > 0) {
          fs.push(i);
        }
      });
      if (this.payload.isDoctor) this.filter(this.payload.allowedFolderTypes[0]);
      if (this.payload.isSupport) this.filter(0);
      if (this.payload.isPharmacy) this.filter(999);
      this.folderCount = fc;
      this.folders = fs;
      this.updateCount();
      this.setLoading(false);
    },
    async getCannedMessages() {
      this.setLoading(true);
      const result = await store.dispatch("GetCannedMessages");
      if (!result) {
        this.setLoading(false);
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
        return;
      }
      this.CannedMessages = [] as string[];
      var array = result as string[];
      array.forEach((v) => {
        this.CannedMessages.push(v);
      });
      this.setLoading(false);
    },
    async sendPatientMessage() {
      if (this.NewMessage.trim() === "") {
        this.$bvToast.toast("Message cannot be empty! Please enter a message and try again.", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "warning", solid: true, toaster: "b-toaster-top-center" });
        return false;
      }
      if (this.folderType < 0) {
        this.$bvToast.toast("Please let us know what this message is in regard to.", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "warning", solid: true, toaster: "b-toaster-top-center" });
        return false;
      }
      this.setLoading(true);
      var postData = { patientId: this.payload.patientId, patientAffiliateID: this.payload.patientAffiliateID, message: this.NewMessage, folderType: this.folderType };
      const result = await store.dispatch("SendPatientMessage", postData);
      if (!result) {
        this.setLoading(false);
        this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true, variant: "danger", solid: true, toaster: "b-toaster-top-center" });
        return;
      }
      this.NewMessage = "";
      this.getPatientMessages();
      this.setLoading(false);
      this.$bvToast.toast("Message Sent!", { title: "Success", autoHideDelay: 3000, appendToast: true, variant: "success", solid: true, toaster: "b-toaster-top-center" });
      if (this.payload && this.payload.onSendCallback) this.payload.onSendCallback();
    },
    updateCount() {
      if (!this.payload.newMessageCountCallback) return;
      var unreadCount = 0;
      this.PatientMessages.forEach((v) => {
        if (this.payload.isDoctor && v.readDate === null && v.folderType > 0) {
          unreadCount++;
        } else {
          if (this.payload.isSupport && v.readDate === null && v.folderType == 0) {
            unreadCount++;
          } else {
            if (this.payload.isPharmacy && v.readDate === null && v.folderType == 999) {
              unreadCount++;
            } else {
              if (v.readDate === null) {
                unreadCount++;
              }
            }
          }
        }
      });
      this.payload.newMessageCountCallback(unreadCount);
    },
    async markPatientMessageAsRead(message: PatientMessageView) {
      if (message.readDate === null) {
        message.readDate = new Date().toDateString();
        var postData = { messageId: message.id, patientAffiliateID: message.affiliateID };
        const result = await store.dispatch("MarkPatientMessageAsRead", postData);
        if (!result) {
          this.$bvToast.toast("An error has occurred!", { title: "Error", autoHideDelay: 3000, appendToast: true });
          return;
        }
        this.toggleShowMessage(message.id);

        var msgs = [] as PatientMessageView[];
        this.PatientMessages.forEach((v) => {
          if (v.id == message.id) msgs.push(message);
          else msgs.push(v);
        });
        this.PatientMessages = [] as PatientMessageView[];
        this.PatientMessages = msgs;

        if (this.payload && this.payload.noNewMessagesCallback) {
          if (this._hasUnreadMessages() === false) {
            this.payload.noNewMessagesCallback();
          }
        }
        this.updateCount();
      } else {
        this.toggleShowMessage(message.id);
      }
    },
    toggleShowMessage(ID: number) {
      var foundI = -1;
      for (var i = 0; i < this.showMessages.length; i++) {
        if (this.showMessages[i] == ID) foundI = i;
      }
      if (foundI > -1) {
        this.showMessages.splice(foundI, 1);
      } else {
        this.showMessages.push(ID);
      }
    },
    showMessage(ID: number) {
      var foundID = this.showMessages.filter((obj) => {
        return obj === ID;
      });
      return foundID && foundID.length > 0 ? true : false;
    },
    formatMessageDate(d: string) {
      var local = moment(moment.utc(d)).local();
      return local.format("MM/DD/YYYY h:mm A");
    },
    _hasUnreadMessages() {
      var result = false;
      var message = this.PatientMessages.filter((obj) => {
        return obj.readDate === null;
      });

      if (message.length > 0) {
        result = true;
      } else {
        result = false;
      }

      return result;
    },
    messageClick(msg: string) {
      if (!this.payload.isDoctor) return;
      this.NewMessage = msg;
    },
  },
  components: { BTabs, BTab, BCard, BCardText, BCardSubTitle, BFormSelect, BFormSelectOption, BIcon },
});
