
import { printExpected } from "jest-matcher-utils";
import Vue, { PropType, readonly } from "vue";
import { object, string } from "yup";
import { BButton, BFormInput, BImg, BFormSelect } from "bootstrap-vue";
import { Answer } from "@/interfaces/Answer";
export default Vue.extend({
  name: "select-choice-component",
  props: {
    value: Array as PropType<Answer[]>,
    val: Object,
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selected: false,
      answer: {} as Answer,
    };
  },
  watch: {
    val(nv) {
      this.importData(nv);
    },
    value(nv: Array<Answer>, oVal: Array<Answer>) {
      this.ImportSelection(nv);
    },
  },
  created() {
    this.importData(this.val);
    this.ImportSelection(this.value);
  },

  methods: {
    importData(a: Answer) {
      this.answer = a;
    },
    ImportSelection(a: PropType<Answer[]>) {
      if (a.filter((e) => e.answerID == this.answer.answerID).length > 0) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
    onClick(e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.readonly) {
        this.selected = !this.selected;
        this.$emit("selectedChanged", this.answer, this.selected);
      }
    },
  },
  components: {
    BImg,
  },
});
