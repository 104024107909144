
import Vue from "vue";
import CameraVue from "./CameraVueComponent.vue";
import PictureInput from "vue-picture-input";

export interface IPictureInput extends Element {
  image: string | null;
}

export default Vue.extend({
  props: {
    DragText: String,
    UploadBtnTxt: String,
    TakePhotoBtnTxt: String,
  },
  data() {
    return {
      img: null,
      custom: {
        upload: "<h1>An Error Occurred!</h1>",
        drag: this.DragText,
        tap: this.DragText,
        change: "Change Photo",
      },
      useCamera: false,
      value: null,
      photo: null as string | null,
      select: false,
    };
  },
  watch: {
    photo(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resizeImage(newVal, (i) => {
          this.$emit("update:value", i);
        });
      }
    },
  },
  methods: {
    isMobile() {
      var isMobile = false; //initiate as false
      // device detection

      return isMobile;
    },
    onChange() {
      console.log("New picture selected!");
      if ((this.$refs.pictureInput as IPictureInput).image) {
        console.log("Picture loaded.");
        this.photo = (this.$refs.pictureInput as IPictureInput).image;
      } else {
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    },
    btnUseFile_click() {
      this.select = false;
      window.setTimeout(() => {
        this.select = true;
      }, 300);
    },
    cancelSelfie() {
      this.useCamera = false;
      this.select = false;
      this.$emit("update:value", null);
    },
    onError(error: Error) {
      console.log("On Error Event", error);
    },
    resizeImage(image: string, cb: { (i: any): void; (arg0: string): void }) {
      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement("canvas");
        //var canvas = $("<canvas>", {"id":"testing"})[0];
        var ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);

        var MAX_WIDTH = 400;
        var MAX_HEIGHT = 400;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        var dataurl = canvas.toDataURL("image/png");
        cb(dataurl);
      };

      img.src = image;
    },
  },
  components: { CameraVue, PictureInput },
});
