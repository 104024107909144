
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import moment from "moment";
import { BModal, BIcon } from "bootstrap-vue";
import { mapGetters, mapMutations } from "vuex";
import { number, object, string, date } from "yup";
import { VueTelInput } from "vue-tel-input";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { PropType } from "vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";

export default mixins(CustomValidation).extend({
  name: "BasicInfoView",
  mixins: [CustomValidation],
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
  },
  data() {
    return {
      dobMonth: null as string | null,
      dobDay: null as string | null,
      dobYear: null as string | null,
      gender: null as string | null,
      state: null as string | null,
      phone: "" as string,
      phoneIsValid: false as boolean,
      screen: 0,
      picture: "",
      schema: object().shape({
        gender: string().typeError("Gender is required.").required(),
        dobMonth: number().integer().typeError("Invalid Month.").min(0, "Invalid Month.").max(12, "Invalid Month.").required(),
        dobDay: number().integer().typeError("Invalid Day.").min(0, "Invalid Day.").max(31, "Invalid Day.").required(),
        dobYear: number().integer().typeError("Invalid Year.").min(1900, "Invalid Year.").max(3000, "Invalid Year.").required(),
        state: string().typeError("State is required.").required(),
        dateOfBirthValidation: date().typeError("Invalid Birthdate.").required(),
        phone: string()
          .typeError("Phone Number is required.")
          .matches(/^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/, "Invalid Phone Number.")
          .required("Phone Number is required."),
      }),
      currentSMSStep: 1 as number,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    Patient() {
      return this.q.patient;
    },
    dateOfBirthValidation(): Date | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return moment(this.dateOfBirth, "YYYY-MM-DD").toDate();
      }
      return null;
    },
    dateOfBirth(): string | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return this.dobYear + "-" + this.dobMonth + "-" + this.dobDay;
      }
      return null;
    },
  },
  created: function () {
    this.setupValidation(this.schema);
    this.importData(this.Patient);
    this.setLoading(false);
  },
  watch: {
    Patient: function (newVal: PatientViewModel | null) {
      this.importData(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    dobDay_onKeyUp() {
      if (this.dobDay !== null && this.dobDay.length === 2) {
        (this.$refs.dobYear as HTMLElement).focus();
      }
    },
    dobMonth_onKeyUp() {
      if (this.dobMonth !== null && this.dobMonth.length === 2) {
        (this.$refs.dobDay as HTMLElement).focus();
      }
    },
    importData(newVal: PatientViewModel | null) {
      if (newVal == null) return;
      var mDob = null;
      if (newVal.dateOfBirth !== null && newVal.dateOfBirth.length > 0) {
        mDob = moment(newVal.dateOfBirth, "YYYY-MM-DD");
        this.dobMonth = (mDob.month() + 1).toString();
        this.dobDay = mDob.date().toString();
        this.dobYear = mDob.year().toString();
      }
      this.gender = newVal.gender;
      this.state = newVal.state;
      this.phone = newVal.phone;
    },
    getUpdatedData() {
      var p = Object.assign({}, this.Patient);
      p.dateOfBirth = this.dateOfBirth;
      p.gender = this.gender;
      p.homeState = this.state;
      p.state = this.state;
      p.phone = this.phone;
      return p;
    },
    phone_validate(e: { isValid: any }) {
      this.phoneIsValid = e.isValid;
      //if (this.phone) this.validate("phone");
    },
    async prev_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          console.log(this.getUpdatedData());
          this.$emit("saveandnavigate", "-", this.getUpdatedData());
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    async next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          if (this.$gtm && this.$gtm.enabled() && this.Content.GTMConfig) {
            window.dataLayer?.push({
              event: "basicinfo",
              phone: this.phone,
            });
          }
          this.errors = [];
          console.log(this.getUpdatedData());
          this.$emit("saveandnavigate", "+", this.getUpdatedData());
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    clickMe() {
      this.$bvModal.show("modal-sms");
    },
    displayNextSMSStep() {
      this.currentSMSStep++;
      console.log("step: ", this.currentSMSStep);
    },
  },

  components: { VueTelInput, QuestWrapperComponent, BModal, BIcon },
});
