
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "quest-wrapper-component",
  props: {
    step: { default: "pay", type: String },
    stepType: { default: "none", type: String },
    visitRequired: { default: false, type: Boolean },
    paymentOnly: { default: false, type: Boolean },
    disableProductSelection: { default: false, type: Boolean },
    noProductReview: { default: false, type: Boolean },
    noDrReview: { default: false, type: Boolean },
    readonly: { default: false, type: Boolean },
    isRx: { default: false, type: Boolean },
    chooseProductPriorToAuth: { type: Boolean, default: false },
    getStartedProductsAfterQuestionnaire: { type: Boolean, default: false },
    isRenewal: { type: Boolean, default: false },
    confirmTerms: { default: false, type: Boolean },
    useModifyFlowForRefills: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    notshow() {
      return !this.show;
    },
    medClass() {
      return { current: this.step === "med" ? true : false };
    },
    queClass() {
      return { current: this.step === "que" ? true : false };
    },
    payClass() {
      return { current: this.step === "pay" ? true : false };
    },
    conClass() {
      return { current: this.step === "con" ? true : false };
    },
    visClass() {
      return { current: this.step === "vis" ? true : false };
    },
    basClass() {
      return { current: this.step === "bas" ? true : false };
    },
  },
});
