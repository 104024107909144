
import { Question } from "@/interfaces/Question";
import Vue from "vue";
import PicInputVueComponentVue from "@/components/PicInputVueComponent.vue";

export default Vue.extend({
  name: "question-single-choice",
  props: {
    value: Object,
    showerror: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      question: null as Question | null,
      base64: null as string | null,
    };
  },
  created() {
    this.importData(this.value);
  },
  watch: {
    value(newVal) {
      this.importData(newVal);
    },
    base64(newVal) {
      if (this.question == null) return;
      this.question.selectedAnswers = [{ answerText: newVal, followUpQuestionID: 0, order: 0, answerID: 0 }];
    },
  },
  methods: {
    btnChangePhoto_click() {
      this.base64 = null;
    },
    importData(newVal: Question) {
      this.question = newVal;
      this.base64 = this.question.selectedAnswers && this.question.selectedAnswers.length > 0 && this.question.selectedAnswers[0] !== null ? this.question.selectedAnswers[0].answerText : null;
    },
  },
  components: { PicInputVueComponentVue },
});
