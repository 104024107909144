import moment from "moment";
import { VueConstructor } from "vue/types/umd";

import { FolderTypeEnum } from "@/interfaces/FolderTypeEnum";

export default {
  install(Vue: VueConstructor) {
    Vue.filter("fieldToPlaceholder", function (value: string | null) {
      let result = value;
      if (value === null) {
        return result;
      }

      const field = document.getElementById(value);
      if (field) result = field.getAttribute("placeholder");

      return result;
    });

    Vue.filter("makeDosageSingular", function (value: string | null) {
      let result = value;
      if (typeof value === "undefined" || value === null) {
        return result;
      }

      if (value[value.length - 1] == "s") result = value.substring(0, value.length - 1);

      return result;
    });

    Vue.filter("removeDosageForm", function (value: string | null) {
      let result = value;
      if (value === null) {
        return result;
      }

      result = value.replace("Tablets", "");

      return result;
    });

    Vue.filter("removeDosageAmount", function (value: string | null) {
      let result = value;
      if (typeof value === "undefined" || value === null) {
        return result;
      }

      result = value.replace("20mg", "");
      result = result.replace("5mg", "");

      return result;
    });

    Vue.filter("toLowerCase", function (value: string | null) {
      const result = value;
      if (result === null) {
        return result;
      }

      return result.toLowerCase();
    });

    Vue.filter("toUpperCase", function (value: string | null) {
      const result = value;
      if (result === null) {
        return result;
      }

      return result.toUpperCase();
    });

    Vue.filter("toDateShort", function (value: string | null) {
      const local = moment(value);
      return local.format("M/D/YY");
    });

    Vue.filter("toLocalDateTime", function (value: string | null) {
      const local = moment(moment.utc(value)).local();
      return local.format("M/D/YYYY h:mm A");
    });

    Vue.filter("toLocalDate", function (value: string | null) {
      const local = moment(moment.utc(value)).local();
      return local.format("M/D/YYYY");
    });

    Vue.filter("toLocalDateShort", function (value: string | null) {
      const local = moment(moment.utc(value)).local();
      return local.format("M/D/YY");
    });

    Vue.filter("toLocalDateNoYear", function (value: string | null) {
      const local = moment(moment.utc(value)).local();
      if (!local || !local.isValid()) return "";
      return local.format("M/D");
    });

    Vue.filter("toFormattedPhone", function (value: string | null) {
      const cleaned = ("" + value).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    });

    Vue.filter("toHumanFolder", function (value: FolderTypeEnum | null) {
      switch (value) {
        case FolderTypeEnum.ED:
          return "Erectile Dysfunction";
        case FolderTypeEnum.Support:
          return "Support";
        case FolderTypeEnum.Vuity:
          return "Presbyopia";
        case FolderTypeEnum.WoundCare:
          return "Wound Care";
        case FolderTypeEnum.Pharmacy:
          return "Pharmacist";
        case FolderTypeEnum.PeptideWeightLoss:
          return "Weight Loss";
        case FolderTypeEnum.OnBoard:
          return "On Board";
        case FolderTypeEnum.HairLoss:
          return "Hair Loss";
        case FolderTypeEnum.Ptosis:
          return "Ptosis";
        case FolderTypeEnum.FemaleArousal:
          return "Female Arousal";
        case FolderTypeEnum.Ondansetron:
          return "Nausea Medication";
        case FolderTypeEnum.Bimatoprost:
          return "Bimatoprost";
        default:
          return "Folder";
      }
    });
  },
};
